@use "../global" as g;

.p-sitemap {

    .sitemap {

        &__top {
            padding-bottom: 90px;

            .l-inner {
                width: calc(100% - 60px);
                max-width: 1100px;
                margin: 0 auto;
            }

            &__listBox {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: space-between;
            }

            &__list {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 30%;

                @include g.mq-down(lg) {
                    width: 100%;
                }

                &__item {
                    width: 100%;
                    margin-bottom: 29px;

                    @include g.mq-down(lg) {
                        margin-bottom: 25px;
                    }

                    &.--w50 {
                        width: 49%;
                    }

                    &.--w40 {
                        width: 41%;
                    }

                    &.--ml51 {
                        margin-left: 51%;
                    }

                    a {
                        position: relative;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: space-between;
                        padding-right: 30px;
                        padding-bottom: 7px;
                        border-bottom: 1px solid g.$grey;
                        font-size: 13px;
                        font-weight: 500;
                        letter-spacing: 0.04em;
                        color: g.$blue;

                        @include g.mq-down(lg) {
                            font-size: 12px;
                        }
                    }

                    &__en {
                        font-weight: 400;
                        letter-spacing: 2px;
                        font-size: 1.2rem;

                        &.--gold {
                            color: g.$gold;
                        }
                    }

                    &__arrow {
                        position: absolute;
                        top: -2px;
                        right: 4px;
                        width: 16px;
                        height: 16px;

                        svg {
                            fill: g.$blue;
                            width: 100%;
                            height: 100%;
                        }

                        &.--gold {
                            svg {
                                fill: g.$gold;
                            }
                        }

                    }

                    &__icon {
                        position: absolute;
                        top: 2px;
                        right: 4px;
                        width: 15px;


                    }
                }
            }
        }
    }
}
