@use "../global" as g;

.p-small-wedding {
  .mvSec {
    margin-bottom: 90px;

    @include g.mq-down(lg) {
      margin-bottom: 80px;
    }

    .inner {
      figure {
        width: 100%;
        height: 660px;
        @include g.imgFit();

        @include g.mq-down(lg) {
          height: 100vw;
        }
      }
    }
  }

  .outlineSec {
    margin-bottom: 80px;

    @include g.mq-down(lg) {
      margin-bottom: 60px;
    }

    .inner {
      width: calc(100% - 80px);
      margin: 0 auto;

      .midashi {
        margin-bottom: 30px;
        font-size: 2rem;
        color: g.$blue;
        line-height: 1.8;
        letter-spacing: 4px;
        text-align: center;


        @include g.mq-down(lg) {
          margin-bottom: 20px;
          font-size: 1.8rem;
          text-align: left;
        }
      }

      .read {
        line-height: 1.8;
        text-align: center;

        @include g.mq-down(lg) {
          text-align: left;
        }
      }
    }
  }

  .recommendSec {
    padding: 0 0 120px;

    @include g.mq-down(lg) {
      padding: 0 0 60px;
    }

    .inner {
      width: calc(100% - 80px);
      max-width: 1080px;
      margin: 0 auto;

      .midashi {
        margin-bottom: 35px;
        color: g.$blue;

        .en {
          position: relative;
          margin-bottom: 5px;
          font-size: 2.8rem;
          letter-spacing: 4px;

          @include g.mq-down(lg) {
            font-size: 2.2rem;
          }

          span {
            background: #fff;
            display: inline-block;
            position: relative;
            z-index: 1;
            padding-right: 40px;

            @include g.mq-down(lg) {
              padding-right: 20px;
            }
          }

          &::after {
            content: "";
            background: #d0cec6;
            width: calc(100% + calc(50vw - 550px));
            height: 1px;
            display: block;
            position: absolute;
            bottom: 50%;
            left: 0;
            z-index: 0;

            @include g.mq-down(lg) {
              width: calc(100% + 30px);
            }
          }
        }

        .ja {
          letter-spacing: 1px;
        }
      }

      .list {
        gap: 50px 3.45%;

        li {
          width: percentage(335 / 1080);
          gap: 37.5px 4.15%;

          @include g.mq-down(lg) {
            width: 100%;
            margin-bottom: 30px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          figure {
            margin-bottom: 18px;
          }

          .caption {
            p {
              font-size: 1.6rem;
              letter-spacing: 3px;
              text-align: center;

              @include g.mq-down(lg) {
                text-align: left;
                line-height: 2;
              }
            }
          }
        }
      }
    }
  }

  .reasonSec {
    background: g.$yellow-thin;
    padding: 120px 0 10px;

    @include g.mq-down(lg) {
      padding: 70px 0 10px;
    }

    .inner {
      padding-left: calc(50vw - 550px);

      @include g.mq-down(lg) {
        padding: 0 0 0 30px;
      }

      .midashi {
        margin-bottom: 50px;
        color: g.$blue;

        .en {
          margin-bottom: 5px;
          font-size: 2.8rem;
          letter-spacing: 4px;
          position: relative;

          @include g.mq-down(lg) {
            font-size: 2.4rem;
          }

          span {
            background: g.$yellow-thin;
            display: inline-block;
            position: relative;
            z-index: 1;
            padding-right: 40px;

            @include g.mq-down(lg) {
              padding-right: 20px;
            }
          }

          &::after {
            content: "";
            background: #d0cec6;
            width: 100%;
            height: 1px;
            display: block;
            position: absolute;
            bottom: 50%;
            left: 0;
            z-index: 0;

            @include g.mq-down(lg) {
              width: calc(100% + 30px);
            }
          }
        }

        .ja {
          letter-spacing: 1px;
        }
      }

      .slides {
        position: relative;
        margin-bottom: 100px;
      }
    }
  }

  .villaSec {
    padding: 100px 0 130px;

    @include g.mq-down(lg) {
      padding: 80px 0 80px;
    }

    .inner {

      .midashi {
        text-align: center;
        color: g.$blue;
        margin-bottom: 80px;

        .en {
          font-size: 2.6rem;
          letter-spacing: 4px;

          @include g.mq-down(lg) {
            font-size: 2.4rem;
          }
        }

        .ja {
          font-size: 2rem;
          letter-spacing: 4px;

          @include g.mq-down(lg) {
            font-size: 1.6rem;
            letter-spacing: 2px;
          }
        }
      }

      .box {
        display: flex;
        align-items: center;

        @include g.mq-down(lg) {
          display: block;
        }


        &:nth-of-type(1) {
          padding-left: calc(50vw - 1366px / 2);
          margin-bottom: 90px;

          @include g.mq-down(lg) {
            padding-left: 0;
            margin-bottom: 60px;
          }
        }

        &:nth-of-type(2) {
          padding-right: calc(50vw - 1366px / 2);
          flex-direction: row-reverse;

          @include g.mq-down(lg) {
            padding-left: 0;
          }

          .text {
            margin-left: g.pcminvw(143);
            padding-right: g.pcminvw(93);
            padding-left: 0;

            @include g.mq-down(lg) {

              padding-right: 0;
            }
          }

          .photo {
            @include g.mq-down(lg) {
              width: calc(100% - 20px);
              margin-left: 20px;
            }

            .slides {
              .slick-dots {
                text-align: left;
              }
            }
          }
        }

        .photo {
          width: g.pcminvw(700);
          height: 450px;

          @include g.mq-down(lg) {
            width: calc(100% - 20px);
            height: g.spvw(228);
            margin-bottom: 50px;
          }

          .slides {
            .slide {
              figure {
                width: g.pcminvw(700);
                height: 450px;
                @include g.imgFit();

                @include g.mq-down(lg) {
                  width: 100%;
                  height: g.spvw(228);
                }
              }
            }

            .slick-dots {
              text-align: right;
            }
          }

        }

        .text {
          width: 523px;
          padding-left: g.pcminvw(93);

          @include g.mq-down(lg) {
            width: calc(100% - 80px);
            margin: 0 auto;
            padding-left: 0;
          }

          .komidashi {
            color: g.$blue;
            margin-bottom: 25px;

            .en {
              font-size: 2.4rem;
              letter-spacing: 2px;

              @include g.mq-down(lg) {
                font-size: 2rem;
              }
            }

            .ja {
              font-size: 1.3rem;
              letter-spacing: 2px;
            }
          }

          .read {
            font-size: 1.3rem;
            margin-bottom: 30px;
            line-height: 1.8;
          }

          .spec {
            display: flex;
            flex-wrap: wrap;
            font-size: 1.3rem;

            @include g.mq-down(lg) {
              display: block;
            }

            dt {
              width: 160px;
              padding-right: 20px;
              color: g.$blue;
              font-weight: 700;
              font-size: 1.2rem;
              margin-bottom: 5px;
            }
          }

          .btn {
            display: inline-block;
            margin-top: 30px;
          }
        }
      }
    }
  }

  .ceremonyStyleSec {
    background: g.$yellow-thin;
    padding: 90px 0;

    @include g.mq-down(lg) {
      padding: 60px 0;
    }

    .inner {
      width: calc(100% - 60px);
      max-width: 1100px;
      margin: 0 auto;

      .midashi {
        margin-bottom: 35px;
        color: g.$blue;

        .en {
          margin-bottom: 5px;
          font-size: 2.8rem;
          letter-spacing: 2px;

          @include g.mq-down(lg) {
            font-size: 2.2rem;
          }
        }

        .ja {
          letter-spacing: 1px;
        }
      }

      .list {
        li {
          @include g.mq-down(lg) {
            margin-bottom: 40px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        figure {
          margin-bottom: 18px;

          @include g.mq-down(lg) {
            margin-bottom: 10px;
          }
        }

        img {
          @include g.mq-down(lg) {
            height: 60vw;
            object-fit: cover;
          }
        }

        .caption {
          .komidashi {
            margin-bottom: 13px;
            color: g.$blue;
            font-size: 1.6rem;
            letter-spacing: 1px;

            @include g.mq-down(lg) {
              margin-bottom: 8px;
            }
          }

          .read {
            margin-bottom: 30px;
            font-size: 13px;
            line-height: 1.8;
          }
        }
      }
    }
  }

  .planSec {
    padding: 100px 0;

    @include g.mq-down(lg) {
      padding: 70px 0;
    }

    .inner {
      width: calc(100% - 40px);
      max-width: 1100px;
      margin: 0 auto;

      .midashi {
        color: g.$blue;
        text-align: center;
        margin-bottom: 40px;

        @include g.mq-down(lg) {
          margin-bottom: 30px;
        }

        .en {
          font-size: 1.8rem;
          letter-spacing: 2px;
          margin-bottom: 5px;

          @include g.mq-down(lg) {
            font-size: 1.8rem;
          }
        }

        .ja {
          font-size: 2rem;
          letter-spacing: 2px;

          @include g.mq-down(lg) {
            font-size: 1.4rem;
          }
        }
      }

      .plan {

        &__list {

          @include g.mq-down(lg) {
            margin-top: 50px;
          }

          &__item {

            padding: 35px;
            border: 1px solid g.$grey;

            @include g.mq-down(lg) {
              padding: 20px;
            }

            &:nth-child(n+2) {
              margin-top: 30px;

              @include g.mq-down(lg) {
                margin-top: 30px;
              }
            }

            &>a {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
            }

            &__thumbnail {
              width: 30.5%;
              height: 314px;

              @include g.mq-down(lg) {
                width: 100%;
                height: 78.667vw;
              }

              figure {
                width: 100%;
                height: 100%;
                @include g.imgFit();
              }
            }

            &__detail {
              width: 61%;
              padding-top: 25px;

              @include g.mq-down(lg) {
                width: 100%;
                padding-top: 15px;
              }

            }

            &__name {
              margin-bottom: 15px;
              font-size: 18px;
              font-weight: bold;
              letter-spacing: 0.05em;
              line-height: 1.44;
              color: g.$blue;

              @include g.mq-down(lg) {
                font-size: 16px;
              }
            }

            &__tag {
              display: inline-block;
              margin-right: 1px;
              padding: 3px 9px;
              font-size: 11px;
              font-weight: 500;
              color: #fff;
              letter-spacing: 0.05em;
              text-align: center;
              background: g.$blue;
            }

            &__tagBox {
              margin-bottom: 20px;
            }

            &__label {
              display: inline-block;
              position: absolute;
              top: 0;
              left: 0;
              width: 85px;
              font-size: 13px;
              font-weight: bold;
              color: g.$text-color;
              letter-spacing: 0.05em;

              @include g.mq-down(lg) {
                position: static;
              }
            }

            &__price,
            &__period,
            &__number {
              position: relative;
              padding-left: 80px;
              letter-spacing: 0.05em;

              @include g.mq-down(lg) {
                padding-left: 0;
              }
            }

            &__price {
              vertical-align: baseline;
              margin-bottom: 13px;
              font-size: 25px;
              font-weight: bold;
              color: g.$blue;
              letter-spacing: 0.05em;
              line-height: 1;
              display: flex;
              align-items: flex-end;

              @include g.mq-down(lg) {
                margin-bottom: 20px;
                font-size: 22px;
              }

              .label {
                top: auto;
                bottom: 2px;

                @include g.mq-down(lg) {
                  font-size: 1.3rem;
                  color: g.$text-color;
                }
              }
            }

            &__period {
              margin-bottom: 10px;
            }

            &__number {
              margin-bottom: 23px;
            }

            &__link {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  .recommendfairSec {
    background-image: url(../images/small-wedding/img_recommendfair-01_pc.jpg);

    @include g.mq-down(lg) {
      background-image: url(../images/small-wedding/img_recommendfair-01_sp.jpg);
    }
  }

  .reportSec {
    background: g.$yellow-thin;
    padding: 60px 0 80px;

    .inner {
      width: 100%;
      margin: 0 auto;

      .midashi {
        color: g.$blue;
        text-align: center;
        margin-bottom: 40px;

        .en {
          font-size: 3rem;
          letter-spacing: 2px;
          margin-bottom: 5px;

          @include g.mq-down(lg) {
            font-size: 2.2rem;
          }

        }
      }

      .reportSlides {
        width: 100%;
        max-width: 1120px;
        margin: 0 auto 60px;

        .slide {
          margin: 0 10px;

          @include g.mq-down(lg) {
            margin: 0 10px;
            width: calc(100vw - 40px);
          }

          figure {
            width: 100%;
            height: 246px;

            @include g.mq-down(lg) {
              height: 56vw;
            }

            @include g.imgFit();
          }

          .caption {
            background: g.$yellow-thin;
            text-align: center;
            color: g.$blue;
            font-size: 1.6rem;
            letter-spacing: 2px;
            padding: 20px 20px;
          }
        }

        .slick-prev {
          left: -25px;

          @include g.mq-down(lg) {
            left: 20px;
          }

        }

        .slick-next {
          right: -25px !important;

          @include g.mq-down(lg) {
            right: 20px !important;
          }
        }
      }

      .more {
        text-align: center;
        padding: 0 30px;
      }
    }
  }

}
