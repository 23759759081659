@use "../breakpoint" as b;
$text-color: #333;
$blue: #678093;
$blue-bright: #004781;
$blue-thin: #5586B5;
$green: #4F8E59;
$orange: #deb465;
$grey: #606060;
$grey-thin: #D9E0E4;
$gold: #B49E55;
$gold-dark: #AFA895;
$red: #af755b;
$yellow-thin: #F0EFE9;
$white: #FFFFFF;
$off-white: #FCFCFA;
$danger: #FF0000;
