@use "../global" as g;

.wrapper {
  overflow: hidden;
}

.lower {
  main {
    margin-top: 100px;

    @include g.mq-down(md) {
      margin-top: 75px;
    }
  }
}

.ly_w1080 {
  width: calc(100% - 40px);
  max-width: 1080px;
  margin: 0 auto;
}

.ly_w1000 {
  width: calc(100% - 40px);
  max-width: 1000px;
  margin: 0 auto;
}

.ly_w950 {
  width: calc(100% - 40px);
  max-width: 950px;
  margin: 0 auto;
}

.p-blog {
  aside {
    @include g.mq-down(lg) {
      width: 100%;
      margin-top: 0;
      margin-bottom: 60px;
    }

    .asideBlock {
      margin-bottom: 40px;
      color: g.$blue;

      .komidashi {
        margin-bottom: 20px;
        padding-bottom: 5px;
        border-bottom: 1px solid g.$grey-thin;
        font-size: 1.6rem;
        letter-spacing: 3px;
      }

      li {
        margin-bottom: 20px;
      }

      .select-box {
        width: 100%;
        padding: 5px 0;
        border: none;
        color: #999;

        @include g.mq-down(md) {
          padding: 15px 0;
        }
      }

      .selectbox {
        width: 100%;
        position: relative;
        overflow: hidden;

        @include g.mq-down(lg) {
          width: 100%;
        }
      }

      .selectbox select {
        background: #fff;
        background-image: none;
        width: 100%;
        padding: 7px 38px 7px 5px;
        padding-right: 1em;
        border: none;
        border: 1px solid #ccc;
        border-radius: 5px;
        color: #ccc;
        text-indent: .01px;
        cursor: pointer;

        -webkit-appearance: none;
        appearance: none;
        box-shadow: none;

        @include g.mq-down(md) {
          padding: 7px 38px 7px 7px;
        }

        outline: none;
        text-overflow: ellipsis;
      }

      .selectbox select::-ms-expand {
        display: none;
      }

      .selectbox::before {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        right: 10px;
        bottom: 0;
        margin: auto;
        padding: 0;
        border-top: 6px solid #ccc;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;

        pointer-events: none;
      }
    }
  }
}
