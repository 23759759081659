@use "../global" as g;

.spaceSec {
  padding: 120px 0 140px;

  @include g.mq-down(lg) {
    padding: 60px 0 80px;
  }

  &:nth-of-type(odd) {
    background: g.$yellow-thin;
  }

  &:nth-of-type(even) {
    .box {
      .photo {
        @include g.mq-down(lg) {
          width: calc(100% - 20px);
          height: g.spvw(355);
          margin-left: 20px;
        }
      }
    }
  }

  .midashi {
    margin-bottom: 50px;

    @include g.mq-down(lg) {
      margin-bottom: 40px;
      padding-left: 40px;
    }

    .en {
      font-size: 1.8rem;
      color: g.$blue;
      margin-bottom: 5px;

      @include g.mq-down(lg) {
        margin-bottom: 0;
      }
    }

    .ja {
      font-size: 1.4rem;
      color: g.$blue;
    }
  }

  .btn {
    @include g.mq-down(lg) {
      display: inline-block;
      margin: 40px 20px 0;
    }

    span {
      width: 100%;
    }
  }


  .box {
    display: flex;
    padding-right: calc(50vw - 1080px / 2);
    margin-bottom: 40px;

    @include g.mq-down(lg) {
      display: block;
      padding-right: 0;
      margin: 0;

    }

    .photo {
      width: g.pcvw(760);
      height: 530px;

      @include g.mq-down(lg) {
        width: calc(100% - 20px);
        height: g.spvw(355);
      }

      figure {
        width: 100%;
        height: 100%;
        @include g.imgFit();
      }
    }

    .text {
      width: calc(100% - g.pcvw(760));
      padding-left: min(g.pcvw(90), 90px);
      margin-top: 40px;

      @include g.mq-down(lg) {
        width: 100%;
        padding: 0 40px;
      }

      .catch {
        font-size: 2rem;
        color: g.$blue;
        letter-spacing: 3px;
        line-height: 1.8;
        margin-bottom: 30px;

        @include g.mq-down(lg) {
          font-size: 1.8rem;
        }
      }

      .read {
        line-height: 2;
        margin-bottom: 30px;
      }
    }
  }

  .imgs {
    padding-left: calc(50vw - 866px / 2);
    display: flex;

    @include g.mq-down(lg) {
      width: calc(100% - 40px);
      margin: 0 auto;
      padding: 0;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    figure {
      width: min(g.pcvw(380), 380px);
      height: min(g.pcvw(290), 290px);
      margin-right: min(g.pcvw(20), 20px);
      margin-bottom: 0;

      @include g.mq-down(lg) {
        width: calc(50% - g.spvw(3.5));
        height: g.spvw(165);
        margin-right: 0;

        &:nth-of-type(3) {
          margin-top: g.spvw(7);
          width: 100%;
        }
      }

      &:nth-last-of-type(1) {
        margin-right: 0;
      }

      @include g.imgFit();
    }
  }
}
