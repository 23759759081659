@use "../global" as g;

.p-top {

  // header {
  //   &::after {
  //     background: transparent;
  //   }
  // }
  header {
    opacity: 1;
    transition: opacity .2s;

    &.header-hidden {
      @include g.mq-up(lg) {
        opacity: 0;
      }
    }
  }

  .mvSec {
    // background-image: url(../images/top/img_mv-01_pc.jpg);
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    width: 100%;
    height: 100vh;
    position: relative;

    @include g.mq-down(lg) {
      // background-image: url(../images/top/img_mv-01_sp.jpg);
    }

    .title {
      width: 100%;
      position: absolute;
      top: calc(50% - 20px);
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      @include g.mq-down(lg) {
        width: 100%;
        top: calc(50% - g.spvw(20));
      }

      .sub {
        width: 200px;
        height: 13px;
        margin: 0 auto;
        line-height: 1;
        opacity: 0;


        @include g.mq-down(lg) {
          width: g.spvw(185);
          height: g.spvw(11.75);
        }
      }

      .catch {
        margin: 35px 0;
        letter-spacing: 8px;
        font-size: 2.8rem;
        line-height: 1.8;
        color: #fff;
        font-weight: 500;
        opacity: 0;

        @include g.mq-down(lg) {
          width: g.spvw(216);
          margin: g.spvw(20) auto;
          letter-spacing: g.spvw(4);
          font-size: g.spvw(18);
        }

      }

      .name {
        width: 174px;
        height: 42px;
        margin: 0 auto;
        opacity: 0;
        line-height: 1;
        //filter: blur(20px);

        @include g.mq-down(lg) {
          width: g.spvw(160);
          height: g.spvw(38.72);
        }
      }

      &.start {
        .sub {
          animation: letter-glow 1s 0s ease both, blur 1s 0s ease both;
          animation-delay: 0.5s;
        }

        .catch {
          animation: letter-glow 1s 0s ease both, blur 1s 0s ease both;
          animation-delay: 1.5s;
          // span {

          //   animation: letter-glow 0.7s 0s ease both;

          //   &:nth-child(1) {
          //     animation-delay: 0.5s;
          //   }

          //   &:nth-child(2) {
          //     animation-delay: 0.7s;
          //   }

          //   &:nth-child(3) {
          //     animation-delay: 0.9s;
          //   }

          //   &:nth-child(4) {
          //     animation-delay: 1.1s;
          //   }

          //   &:nth-child(5) {
          //     animation-delay: 1.3s;
          //   }

          //   &:nth-child(6) {
          //     animation-delay: 1.5s;
          //   }

          //   &:nth-child(7) {
          //     animation-delay: 1.7s;
          //   }

          //   &:nth-child(8) {
          //     animation-delay: 1.9s;
          //   }

          //   &:nth-child(9) {
          //     animation-delay: 2.1s;
          //   }

          //   &:nth-child(10) {
          //     animation-delay: 2.3s;
          //   }

          //   &:nth-child(11) {
          //     animation-delay: 2.5s;
          //   }

          //   &:nth-child(12) {
          //     animation-delay: 2.7s;
          //   }

          //   &:nth-child(13) {
          //     animation-delay: 2.9s;
          //   }

          //   &:nth-child(14) {
          //     animation-delay: 3.1s;
          //   }

          //   &:nth-child(15) {
          //     animation-delay: 3.3s;
          //   }

          //   &:nth-child(16) {
          //     animation-delay: 3.5s;
          //   }

          //   &:nth-child(17) {
          //     animation-delay: 3.7s;
          //   }

          //   &:nth-child(18) {
          //     animation-delay: 3.9s;
          //   }

          //   &:nth-child(19) {
          //     animation-delay: 4.1s;
          //   }

          //   &:nth-child(20) {
          //     animation-delay: 4.3s;
          //   }

          //   &:nth-child(21) {
          //     animation-delay: 4.5s;
          //   }

          //   &:nth-child(22) {
          //     animation-delay: 4.7s;
          //   }

          //   &:nth-child(23) {
          //     animation-delay: 4.9s;
          //   }

          //   &:nth-child(24) {
          //     animation-delay: 5.1s;
          //   }

          //   &:nth-child(25) {
          //     animation-delay: 5.3s;
          //   }

          //   &:nth-child(26) {
          //     animation-delay: 5.5s;
          //   }

          //   &:nth-child(27) {
          //     animation-delay: 5.7s;
          //   }
          // }
        }

        .name {
          animation: letter-glow 1s 0s ease both, blur 1s 0s ease both;
          animation-delay: 2.5s;
        }
      }


      @keyframes letter-glow {
        0% {
          opacity: 0;
          text-shadow: 0px 0px 1px rgba(255, 255, 255, 0.1);
        }

        66% {
          opacity: 1;
          text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.9);
        }

        77% {
          opacity: 1;
        }

        100% {
          opacity: 0.7;
          text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.0);
        }
      }

      @keyframes blur {
        0% {
          opacity: 0;
          filter: blur(10px);
        }

        100% {
          opacity: 1;
          filter: blur(0);
        }
      }
    }

    .video-area {
      position: absolute;
      top: calc(50% - 1px);
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      width: 100%;
      height: 100%;
      overflow: hidden;

      // &::before {
      //   content: "";
      //   display: inline-block;
      //   width: 100%;
      //   height: 100%;
      //   background-image: linear-gradient(180deg, rgba(0, 0, 0, .2) 0%, rgba(0, 0, 0, .1) 100%);
      //   mix-blend-mode: multiply;

      //   z-index: 0;
      // }

      #video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 177.77777778vh;
        height: 56.25vw;
        min-height: 100%;
        min-width: 100%;
        z-index: -1;


      }
    }
  }

  .newsSec {
    position: relative;

    .inner {
      width: calc(100% - 80px);
      max-width: 1080px;
      margin: 0 auto;
      position: absolute;
      top: 48px;
      left: 0;
      padding-left: calc(50vw - 1080px / 2);

      @include g.mq-down(lg) {
        padding: 30px 0 50px;
        position: static;
      }

      .box {
        display: flex;

        @include g.mq-down(lg) {
          display: block;
        }

        .midashi {
          width: 80px;
          font-weight: 700;
          color: g.$gold-dark;

          @include g.mq-down(lg) {
            font-size: 1.3rem;
            margin-bottom: 20px;
          }
        }

        .list {
          li {
            margin-bottom: 15px;

            @include g.mq-down(lg) {
              margin-bottom: 10px;

            }

            &:last-child {
              margin-bottom: 0;
            }

            a {
              color: g.$gold-dark;
              font-weight: 700;
              text-decoration: underline;

              @include g.mq-down(lg) {
                font-weight: 400;
                font-size: 1.3rem;
              }
            }
          }
        }
      }
    }
  }

  .aboutSec {
    .inner {
      padding-left: calc(50vw - 1080px / 2);

      @include g.mq-down(lg) {
        width: calc(100% - 40px);
        margin: 0 auto;
        padding-left: 0;
      }

      .box {
        display: flex;
        flex-direction: row-reverse;
        align-items: end;
        justify-content: space-between;

        @include g.mq-down(md) {
          display: block;
        }

        .text {
          //padding-top: 220px;
          width: calc(1080px / 2);

          @include g.mq-down(md) {
            width: 100%;
          }

          .midashi {
            margin-bottom: 60px;
            width: calc(1080px / 2);

            @include g.mq-down(lg) {
              width: 100%;
              max-width: 335px;
              margin-bottom: 40px;
            }
          }

          .catch {
            font-size: 2.4rem;
            letter-spacing: 4px;
            line-height: 2;
            padding: 0 min(g.pcvw(60), 60px);
            margin-bottom: 30px;

            @include g.mq-down(lg) {
              padding: 0 20px;
              font-size: 1.6rem;
            }
          }

          .read {
            font-size: 1.5rem;
            line-height: 2.4;
            padding: 0 min(g.pcvw(60), 60px);

            @include g.mq-down(lg) {
              padding: 0 20px;
              font-size: 1.3rem;
            }
          }

          .btn {
            display: inline-block;
            font-size: 1.2rem;
            margin-top: 30px;
            margin: 0 min(g.pcvw(60), 60px);

            .icon {
              margin-left: 40px;

              @include g.mq-down(lg) {
                margin-left: 20px;
              }
            }
          }
        }

        .photo {
          width: g.pcvw(525);
          height: 780px;

          @include g.mq-down(lg) {
            width: 100%;
            padding: 0 20px;
            height: g.spvw(400);
            margin-bottom: 40px;
          }

          figure {
            width: 100%;
            height: 100%;
            @include g.imgFit(50%, 90%);
          }
        }
      }
    }
  }

  .locationSec {
    padding-top: 180px;
    padding-bottom: 220px;
    position: relative;

    @include g.mq-down(lg) {
      padding-top: 80px;
      padding-bottom: 124px;
    }

    &::before {
      content: "";
      display: block;
      width: g.pcvw(385);
      height: 585px;
      background-image: url(../images/top/obj_location-01.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: -2;

      @include g.mq-down(lg) {
        width: g.spvw(188);
        height: 281px;
      }
    }

    &::after {
      content: "";
      display: block;
      width: 131px;
      height: 524.86px;
      background-image: url(../images/top/obj_chapel-01.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: absolute;
      bottom: -163px;
      right: g.pcvw(163);
      z-index: 0;

      @include g.mq-down(lg) {
        width: g.spvw(61.3);
        height: g.spvw(245.59);
        right: 40px;
        bottom: g.spvw(-163);
        z-index: 1;
      }
    }

    .inner {
      padding-right: calc(50vw - 1080px / 2);

      .box {
        display: flex;
        justify-content: space-between;

        @include g.mq-down(lg) {
          display: block;
        }

        .photo {
          width: g.pcvw(688);
          display: flex;
          justify-content: space-between;

          @include g.mq-down(lg) {
            width: calc(100% - 40px);
            margin-bottom: 50px;
          }

          .img1 {
            width: g.pcvw(241);
            height: 410px;

            @include g.mq-down(lg) {
              width: g.spvw(145);
              height: g.spvw(280);
            }

            figure {
              width: 100%;
              height: 100%;
              @include g.imgFit(50%, 100%);
            }
          }

          .img2 {
            width: g.pcvw(442);
            height: 410px;

            @include g.mq-down(lg) {
              width: g.spvw(185);
              height: g.spvw(280);
            }

            figure {
              width: 100%;
              height: 100%;
              @include g.imgFit(50%, 100%);
            }
          }
        }

        .text {
          width: 397px;

          @include g.mq-down(lg) {
            width: calc(100% - 80px);
            margin: 0 auto;

          }

          .catch {
            font-size: 2.4rem;
            letter-spacing: 3px;
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              font-size: 1.6rem;
            }
          }

          .read {
            font-size: 1.5rem;
            line-height: 2.5;
            letter-spacing: 3px;

            .space {
              display: block;
              padding-bottom: 15px;
            }

            @include g.mq-down(lg) {
              font-size: 1.3rem;
              line-height: 2;
            }
          }

          .btn {
            display: inline-block;
            font-size: 1.2rem;
            margin-top: 30px;

            .icon {
              margin-left: 40px;

              @include g.mq-down(lg) {
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
  }

  .chapelSec {
    padding-bottom: 130px;
    background: g.$yellow-thin;

    .visual {
      width: g.pcvw(890);
      height: 540px;

      @include g.mq-down(lg) {
        width: 100%;
        height: g.spvw(375);
        margin-bottom: 30px;
      }

      figure {
        width: 100%;
        height: 100%;
        @include g.imgFit(0, 50%);

        @include g.mq-down(lg) {
          @include g.imgFit(20%, 55%);
        }
      }
    }

    .box {
      padding-left: calc(50vw - 1080px / 2);
      display: flex;
      align-items: end;
      flex-direction: row-reverse;
      margin-top: -156px;

      @include g.mq-down(lg) {
        padding-left: 0;
        margin-top: 0;
        display: block;

      }

      .text {
        width: 465px;
        padding-right: min(g.pcvw(86), 86px);

        @include g.mq-down(lg) {
          width: calc(100% - 80px);
          margin: 0 auto;
          padding-right: 0;
        }

        .midashi {
          margin-bottom: 20px;

          .en {
            width: 210px;
            height: 32px;

            @include g.mq-down(lg) {
              width: 149.57px;
              height: 22.61px;
            }
          }

          .ja {
            font-size: 1rem;
            color: g.$blue;
            margin-top: 10px;
          }
        }

        .catch {
          font-size: 2rem;
          line-height: 2;
          letter-spacing: 4px;
          color: #707070;
          margin-bottom: 10px;

          @include g.mq-down(lg) {
            font-size: 1.8rem;
            letter-spacing: 3px;
          }
        }

        .read {
          line-height: 2;
        }

        .btn {
          display: inline-block;
          font-size: 1.2rem;
          margin-top: 30px;

          .icon {
            margin-left: 40px;
          }
        }
      }

      .photo {
        width: calc(100% - 465px);

        @include g.mq-down(lg) {
          width: 100%;
          padding-left: 40px;
          padding-bottom: 30px;
        }

        .slides {
          .slide {
            margin-right: 10px;

            figure {
              width: 420px;
              height: 595px;
              margin-bottom: 0;
              @include g.imgFit();

              @include g.mq-down(lg) {
                width: 200px;
                height: 250px;
              }
            }
          }

          .slick-dots {
            text-align: left;
          }
        }
      }
    }
  }

  .banquetSec {
    background: g.$yellow-thin;
    padding: 110px 0 163px;
    position: relative;

    @include g.mq-down(lg) {
      padding: 40px 0 100px;
    }

    &::after {
      content: "";
      display: block;
      width: g.pcvw(625);
      height: 100%;
      background-image: url(../images/top/obj_banquet-01.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      mix-blend-mode: multiply;

      @include g.mq-down(lg) {
        width: calc(100% - 20px);
        height: calc(g.spvw(355) + 20px + 250px + 170px);
      }
    }

    .box {
      display: flex;
      justify-content: space-between;
      padding-right: calc(50vw - 1080px / 2);
      padding-bottom: 350px;
      position: relative;
      z-index: 1;

      @include g.mq-down(lg) {
        display: block;
        padding-bottom: 0;
      }

      .photo {
        width: g.pcvw(625);
        height: 520px;

        @include g.mq-down(lg) {
          width: 100%;
          height: auto;
          margin-bottom: 70px;
        }

        &>figure {
          width: 100%;
          height: 100%;

          @include g.mq-down(lg) {
            width: calc(100% - 20px);
            height: g.spvw(355);
            margin-bottom: 20px;
          }

          @include g.imgFit();
        }

        .slides {
          position: absolute;
          bottom: 0;
          left: 0;
          width: calc(100vw - (50vw - 1080px / 2));
          margin-left: calc(50vw - 1080px / 2);

          @include g.mq-down(lg) {
            width: 100%;
            position: relative;
            padding-left: 40px;
            margin-left: 0;
          }

          .slide {
            figure {
              width: 295px;
              height: 385px;
              margin-right: 5px;
              margin-bottom: 0;

              @include g.mq-down(lg) {
                width: 200px;
                height: 295px;
              }

              @include g.imgFit();

            }
          }

          .slick-dots {
            text-align: left;
          }
        }
      }

      .text {
        width: 443px;
        padding-right: min(g.pcvw(40), 40px);

        @include g.mq-down(lg) {
          width: calc(100% - 80px);
          margin: 0 auto;
        }

        .midashi {
          margin-bottom: 20px;

          .en {
            width: 283.05px;
            height: 45.43px;

            @include g.mq-down(lg) {
              width: 182.68px;
              height: 29.32px;
            }
          }

          .ja {
            font-size: 1rem;
            color: g.$blue;
            margin-top: 10px;
          }
        }

        .catch {
          font-size: 2rem;
          line-height: 2;
          letter-spacing: 4px;
          color: #707070;
          margin-bottom: 10px;

          @include g.mq-down(lg) {
            font-size: 1.8rem;
          }
        }

        .read {
          line-height: 2;
        }

        .btn {
          display: inline-block;
          font-size: 1.2rem;
          margin-top: 30px;

          .icon {
            margin-left: 40px;
          }
        }
      }
    }
  }

  .top-othercontentsSec {
    background-image: url(../images/top/bg_othercontents-01.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 120px 0 140px;

    @include g.mq-down(lg) {
      padding: 80px 0 130px;
    }

    .inner {
      width: calc(100% - 40px);
      max-width: 1253px;
      margin: 0 auto;

      @include g.mq-down(lg) {
        width: 100%;
      }

      .slides {
        padding-left: 40px;

        .slide {
          width: 32%;
          max-width: 365px;
          margin: 0 calc(53px / 2);

          @include g.mq-down(lg) {
            width: g.spvw(265);
            max-width: g.spvw(265);
            margin: 0 20px 0 0;
          }

          .block {
            figure {
              width: 100%;
              margin-bottom: 25px;
            }

            .midashi {
              color: g.$gold-dark;
              margin-bottom: 10px;

              .en {
                font-size: 1.8rem;
                letter-spacing: 2px;
                margin-bottom: 5px;
              }

              .ja {
                font-size: 1rem;
              }
            }

            .catch {
              font-size: 1.6rem;
              letter-spacing: 2px;
              margin-bottom: 10px;
            }

            .read {
              font-size: 1.3rem;
              line-height: 2;

              @include g.mq-down(lg) {
                display: none;
              }
            }

            .btn {
              display: inline-block;
              font-size: 1.2rem;
              margin-top: 30px;

              .icon {
                margin-left: 40px;

                @include g.mq-down(lg) {
                  margin-left: 20px;
                }
              }
            }
          }
        }

        .slick-prev {
          left: 5px;
          top: calc(50% - 100px);
        }

        .slick-next {
          right: 5px;
          top: calc(50% - 100px);
        }
      }
    }
  }

  .gallerySec {
    background: g.$yellow-thin;
    padding-bottom: 70px;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: g.pcvw(419);
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-image: url(../images/top/bg_gallery-01.jpg);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      z-index: 0;
      mix-blend-mode: multiply;
    }

    .inner {
      position: relative;
      z-index: 1;

      .midashi {
        margin-bottom: 55px;
        text-align: center;
        color: g.$gold-dark;
        position: relative;
        top: -15px;

        .en {
          width: 243.15px;
          margin: 0 auto 20px;
        }

        .ja {
          font-size: 1rem;
        }
      }

      .list {
        display: flex;
        flex-wrap: wrap;

        li {
          width: calc(100% / 5);
          height: calc(100% / 5);

          @include g.mq-down(lg) {
            width: calc(100% / 2);
            height: calc(100% / 2);

            &:nth-child(n + 7) {
              display: none;
            }
          }

          figure {
            width: 100%;
            height: 100%;
            margin-bottom: 0;
            @include g.imgFit();
          }
        }
      }

      .btn-wrap {
        text-align: center;
      }

      .btn {

        display: inline-block;
        font-size: 1.2rem;
        margin-top: 50px;

        .icon {
          margin-left: 40px;
        }
      }
    }
  }

  .commonplanSec {
    padding: 80px 0 90px;
  }

  .reportSec {
    background-image: url(../images/top/img_report-01_pc.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    width: 100%;
    height: min(g.pcvw(466), 466px);

    @include g.mq-down(lg) {
      background-image: url(../images/top/img_report-01_sp.jpg);
      width: 100%;
      height: g.spvw(375);
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.3;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }

    .block {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      text-align: center;

      .midashi {
        margin-bottom: 20px;

        .en {
          margin-bottom: 5px;

          @include g.mq-down(lg) {
            font-size: 1.6rem;
          }
        }

        .ja {
          font-size: 1rem;

          @include g.mq-down(lg) {
            font-size: 1.3rem;
          }
        }
      }

      .btn {
        display: inline-block;
        font-size: 1.2rem;
        margin-top: 20px;

        .icon {
          margin-left: 20px;
        }
      }
    }
  }

  .topicsSec {
    padding: 140px 0 100px;

    @include g.mq-down(lg) {
      padding: 90px 0 70px;
    }

    .inner {
      padding-left: calc(50vw - 1000px / 2);

      @include g.mq-down(lg) {
        padding-left: 20px;
      }

      .box {
        display: flex;

        @include g.mq-down(lg) {
          display: block;
        }

        .midashi {
          width: 200px;
          font-size: 1.8rem;
          color: g.$blue;
          letter-spacing: 2px;

          @include g.mq-down(lg) {
            width: 100%;
            margin-bottom: 30px;
          }
        }

        .list {
          width: calc(100% - 200px);
          font-size: 1.3rem;

          @include g.mq-down(lg) {
            width: 100%;
          }

          li {
            margin-bottom: 30px;

            a {
              padding-bottom: 20px;
              border-bottom: 1px solid g.$blue;
              display: flex;

              @include g.mq-down(lg) {
                display: block;
              }

              .day {
                color: g.$blue;
                width: min(g.pcvw(120), 120px);

                @include g.mq-down(lg) {
                  width: 100%;
                  margin-bottom: 5px;
                }
              }

              .title {
                padding-right: 20px;
              }
            }
          }

        }
      }
    }
  }
}
