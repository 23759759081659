@use "../global" as g;

.topicpathSec {
  background: #fff;
  padding: 10px 40px;

  @include g.mq-down(lg) {
    padding: 10px 20px;
    font-size: 1.2rem;
  }
}
