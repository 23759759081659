@use "../global" as g;

.p-blog {
  .pageHeadSec {
    padding: 90px 0 70px;

    @include g.mq-down(lg) {
      padding: 40px 0 60px;
    }

    .title {
      .en {
        font-size: 3.4rem;
        letter-spacing: 1px;
        text-align: center;
      }

      .ja {
        text-align: center;
      }
    }
  }

  .archiveSec {
    margin-bottom: 170px;

    @include g.mq-down(lg) {
      margin-bottom: 80px;
    }

    .inner {
      width: calc(100% - 46px);
      max-width: 980px;
      margin: 0 auto;

      @include g.mq-down(lg) {
        width: calc(100% - 80px);
      }

      .box {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;

        @include g.mq-down(lg) {
          display: block;
        }
      }

      .mainBody {
        width: 73.47%;

        @include g.mq-down(lg) {
          width: 100%;
          margin: 0 auto 80px;
        }

        ul {
          display: flex;
          flex-wrap: wrap;

          @include g.mq-down(lg) {
            width: 100%;
          }

          li {
            width: 30.56%;
            margin-right: 4.1%;
            margin-bottom: 30px;

            @include g.mq-down(lg) {
              width: 100%;
              margin-right: 0;
            }

            &:nth-child(3n) {
              margin-right: 0;
            }

            @include g.mq-down(lg) {
              width: 100%;
            }

            figure {
              width: 100%;
              height: 220px;
              margin-bottom: 10px;
              @include g.imgFit();

              @include g.mq-down(lg) {
                height: 80vw;
              }
            }

            .caption {
              position: relative;

              .postCat {
                margin: 0 0 5px;
                color: g.$blue;
                padding-right: 6.5em;

                span {
                  display: inline-block;
                  font-size: 1.2rem;
                }
              }

              .pubDate {
                letter-spacing: 1px;
                color: g.$blue;
                position: absolute;
                top: 0;
                right: 0;
              }

              .more {
                margin-top: 10px;

                span {
                  color: g.$blue;
                  text-decoration: underline;
                }
              }
            }

            a {
              display: block;

              &:hover {
                .caption {
                  .more {
                    span {
                      text-decoration: none;
                    }
                  }
                }
              }
            }
          }
        }

        .wp-pagenavi {
          margin-top: 60px;
          text-align: center;

          //font-size: 1rem;
          @include g.mq-down(lg) {
            margin-top: 20px;
          }

          span {
            display: inline-block;
            border: none;
            color: #999999;
            z-index: 1;
            padding: 0 5px;
            font-weight: 700;
          }

          a {
            border: none;
            color: g.$blue;
            padding: 0 5px;
          }
        }
      }

      aside {
        width: 200px;

        @include g.mq-down(lg) {
          width: 100%;
        }
      }
    }
  }
}
