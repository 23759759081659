@use "../global" as g;

.p-guest {
    .l-inner {
        max-width: 1100px;
        width: calc(100% - 40px);
        margin: auto;
    }

    .l-inner2 {
        max-width: 980px;
        width: calc(100% - 40px);
        margin: auto;
    }

    .guest {
        &__top {
            padding-bottom: 44px;

            &__contentsList {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                &__item {
                    width: 19.4%;

                    @include g.mq-down(lg) {
                        width: 100%;
                        margin-bottom: 5px;
                    }

                    a {
                        position: relative;
                        display: block;
                        padding: 14px 24px 14px 0;
                        border: 1px solid currentColor;
                        font-size: 13px;
                        font-weight: bold;
                        color: g.$blue;
                        letter-spacing: 0.1em;
                        text-align: center;

                        .icon {
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }

        &__detail {
            padding: 80px 0;

            @include g.mq-down(lg) {
                padding: 60px 0;
            }

            &.--gray {
                background-color: g.$yellow-thin;
            }

            &__head {
                padding-bottom: 23px;
                border-bottom: 1px solid g.$blue;
                font-size: 20px;
                color: g.$blue;
                letter-spacing: 2px;
                text-align: center;

                @include g.mq-down(lg) {
                    font-size: 18px;
                    text-align: left;
                    padding-bottom: 15px;
                }
            }

            &__leadTxt {
                margin-top: 35px;
                font-size: 13px;
                letter-spacing: 0.08em;
                line-height: 1.85;
                text-align: center;

                @include g.mq-down(lg) {
                    margin-top: 30px;
                    text-align: left;
                }
            }

            &__txt {
                margin-top: 12px;
                font-size: 13px;
                font-weight: 500;
                letter-spacing: 0.08em;
                line-height: 1.85;
            }

            &__link {
                margin: 12px 0 16px;
            }

            &__borderHead {
                margin-bottom: 26px;
                padding-bottom: 6px;
                border-bottom: 1px solid g.$grey;
                font-size: 16px;
                letter-spacing: 0.1em;

                @include g.mq-down(lg) {
                    margin-bottom: 15px;
                    padding-bottom: 10px;
                }
            }

            &__price {
                &__head {
                    margin-bottom: 26px;
                    padding-bottom: 6px;
                    border-bottom: 1px solid g.$yellow-thin;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0.1em;
                }

                &__list {

                    dt,
                    dd {
                        position: relative;
                        margin-bottom: 21.4px;
                        font-size: 15px;
                        font-weight: 500;
                        letter-spacing: 0.08em;
                    }

                    dt {
                        display: flex;
                        align-items: center;
                        width: 50%;
                        float: left;
                        white-space: nowrap;

                        @include g.mq-down(lg) {
                            width: 56%;
                        }

                        &::after {
                            content: "";
                            display: block;
                            width: 100%;
                            height: 1px;
                            border-bottom: 1px dotted g.$grey;
                            box-sizing: border-box;
                            margin-left: 1em;

                            @include g.mq-down(lg) {
                                display: none;
                            }
                        }

                        span {
                            padding-left: 0.7em;
                            font-size: 12px;
                            letter-spacing: 0.08em;

                            @include g.mq-down(lg) {
                                position: absolute;
                                bottom: 0;
                                transform: translateY(100%);
                                padding-left: 0;
                            }
                        }
                    }

                    dd {
                        margin-left: 51.5%;

                        @include g.mq-down(lg) {
                            margin-left: 59.5%;
                            text-align: right;
                        }
                    }

                    &__marginSp {
                        @include g.mq-down(lg) {
                            margin-bottom: 40px !important;
                        }
                    }
                }

                &__notes {
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0.08em;
                    line-height: 2;
                }
            }
        }

        &__facility {
            &__list {
                margin: 55px 0 0;

                dt,
                dd {
                    margin-bottom: 20px;

                    @include g.mq-down(lg) {
                        margin-bottom: 10px;
                    }
                }

                dt {
                    float: left;
                    font-size: 16px;
                    font-weight: 600;
                    color: g.$blue;
                    letter-spacing: 0.1em;

                    @include g.mq-down(lg) {
                        float: none;
                    }
                }

                dd {
                    min-height: 2em;
                    padding-bottom: 20px;
                    padding-left: 210px;
                    border-bottom: 1px dotted g.$grey;
                    font-size: 13px;
                    font-weight: 500;
                    letter-spacing: 0.08em;
                    line-height: 1.85;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    @include g.mq-down(lg) {
                        min-height: auto;
                        padding-left: 0;
                        margin-bottom: 20px;
                    }
                }

                a {
                    display: inline-block;
                    margin-top: 8px;
                    color: g.$blue;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        &__rental {
            &__subHead {
                margin-top: 42px;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.1em;

                &:first-child {
                    margin-top: 50px;
                }
            }

            &__txt {
                margin-top: 12px;
                font-size: 13px;
                font-weight: 500;
                letter-spacing: 0.08em;
            }

            &__stepList {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 20px 0 50px;

                &__item {
                    display: flex;
                    flex-wrap: wrap;
                    width: 30.4%;

                    @include g.mq-down(lg) {
                        width: 100%;
                        margin-bottom: 20px;
                    }

                    &__number {
                        display: flex;
                        align-items: center;
                        width: 46px;
                        border-right: 1px solid g.$blue;
                        box-sizing: border-box;
                        font-size: 2.4rem;
                        color: g.$blue;

                        @include g.mq-down(lg) {
                            width: 55px;
                        }
                    }

                    &__txt {
                        display: flex;
                        align-items: center;
                        width: calc(100% - 46px);
                        padding-left: 23px;
                        box-sizing: border-box;
                        font-size: 13px;
                        font-weight: 500;
                        letter-spacing: 0.08em;
                        line-height: 1.85;

                        @include g.mq-down(lg) {
                            width: calc(100% - 55px);
                        }

                    }
                }
            }
        }

        &__dressing {
            &__price {
                margin-top: 40px;
            }

            &__contact {
                margin-top: 52px;
            }
        }

        &__send {
            &__block {
                margin-top: 30px;

                &:first-of-type {
                    margin-top: 40px;
                }
            }
        }

        &__reserve {
            padding-bottom: 150px;

            @include g.mq-down(lg) {
                padding-bottom: 100px;
            }

            &__button {
                width: 372px;
                margin: 24px auto 0;

                @include g.mq-down(lg) {
                    max-width: 372px;
                    width: 100%;
                }

                a {
                    position: relative;
                    display: block;
                    padding: 9px 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: g.$blue;
                    line-height: 1.67;
                    text-align: center;
                    border: 1px solid currentColor;
                }

                &__icon {
                    position: absolute;
                    top: 0;
                    right: 17px;
                    bottom: 0;
                    width: 18px;
                    margin: auto;
                }
            }
        }
    }
}
