@use "../global" as g;

.p-report-single {

  .linksSec {
    margin-top: -50px;
    padding: 0 0 100px;

    @include g.mq-down(lg) {
      padding: 0 0 90px;
    }

    .inner {
      width: calc(100% - 60px);
      margin: 0 auto;

      ul {
        display: flex;
        justify-content: center;

        @include g.mq-down(lg) {
          display: block;
        }

        li {
          margin: 0 30px;

          @include g.mq-down(lg) {
            margin: 0 0 20px;
          }

          a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 280px;

            @include g.mq-down(md) {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .mvSec {
    background: linear-gradient(180deg, #fff 0%, #fff 36.61%, g.$grey-thin 36.61%, g.$grey-thin 100%);
    padding-bottom: 60px;
    margin-bottom: 0;

    @include g.mq-down(lg) {
      padding-bottom: 50px;
    }

    .inner {
      width: 100%;
      max-width: 980px;
      margin: 0 auto;

      @include g.mq-down(lg) {
        width: calc(100% - 60px);
      }

      figure {
        margin-bottom: 40px;

        @include g.mq-down(lg) {
          margin-left: -30px;
          width: calc(100% + 60px);
          margin-bottom: 30px;
        }
      }

      .title {
        text-align: center;
        color: g.$blue;
        letter-spacing: 2px;
        font-size: 2.2rem;
        margin-bottom: 40px;

        @include g.mq-down(lg) {
          font-size: 1.8 rem;
          letter-spacing: 1px;
          text-align: left;
          margin-bottom: 20px;
        }
      }

      .data {
        display: flex;
        justify-content: center;

        @include g.mq-down(lg) {
          display: block;
        }

        dl {
          width: 358px;
          text-align: center;
          margin: 0 32px;
          letter-spacing: 1px;

          @include g.mq-down(lg) {
            width: 100%;
            margin: 0 0 10px;
            text-align: left;
          }

          dt {
            color: g.$blue;
            letter-spacing: 2px;
            border-bottom: 1px solid g.$grey;
            margin-bottom: 5px;
            padding-bottom: 5px;
          }
        }
      }
    }
  }

  .contentSec {
    padding: 80px 0 0;

    @include g.mq-down(lg) {
      padding: 40px 0 0;
    }

    .inner {
      width: 700px;
      margin: 0 auto;
      margin-bottom: 30px;
      padding-bottom: 80px;
      border-bottom: 1px solid g.$grey-thin;

      @include g.mq-down(lg) {
        width: calc(100% - 40px);

      }

      .midashi {
        font-size: 2rem;
        color: g.$blue;
        margin-bottom: 40px;
        letter-spacing: 3px;
        text-align: center;

        @include g.mq-down(lg) {
          font-size: 1.8rem;
          text-align: left;
          margin-bottom: 30px;
        }
      }

      .block {
        figure {
          margin-bottom: 20px;

          @include g.mq-down(lg) {
            margin-bottom: 10px;
          }
        }

        .caption {
          margin-bottom: 40px;

          @include g.mq-down(lg) {
            font-size: 1.3rem;
          }
        }
      }
    }
  }

  .plannerSec {
    .inner {
      width: 700px;
      margin: 0 auto 20px;
      padding-bottom: 80px;
      border-bottom: 1px solid g.$grey-thin;

      @include g.mq-down(lg) {
        padding-bottom: 40px;
        width: calc(100% - 60px);
      }

      .midashi {
        color: g.$blue;
        margin-bottom: 40px;

        @include g.mq-down(lg) {
          margin-bottom: 20px;
        }

        .en {
          font-size: 1.8rem;
          letter-spacing: 2px;

          @include g.mq-down(lg) {
            letter-spacing: 1px;
          }
        }

        .ja {
          letter-spacing: 2px;

          @include g.mq-down(lg) {
            letter-spacing: 1px;
          }
        }
      }

      .read {
        line-height: 1.8;

        @include g.mq-down(lg) {
          font-size: 1.3rem;
        }
      }
    }
  }

  .singlePagenaviBox {
    padding-bottom: 160px;

    @include g.mq-down(lg) {
      padding-bottom: 100px;
    }

    .inner {
      width: 700px;
      margin: 0 auto;
      position: relative;

      @include g.mq-down(lg) {
        width: calc(100% - 60px);
      }

      ul {
        display: flex;

        li {
          width: 50%;
          color: g.$blue;
          letter-spacing: 1px;

          &:first-child {
            span {
              display: inline-block;
              margin-left: 10px;
            }
          }

          &:last-child {
            text-align: right;

            span {
              display: inline-block;
              margin-right: 10px;
            }
          }

        }
      }

      .btn {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);

        @include g.mq-down(lg) {
          position: static;
          transform: none;
          border: 1px solid g.$blue;
          text-align: center;
          display: inline-block;
          width: 100%;
          padding: 10px 0;
          margin-top: 30px;

        }

        .icon {
          transform: rotate(180deg);
          margin: 0 20px 0 0;
        }
      }
    }
  }
}
