@use "../global" as g;

footer {

  .sitemapSec {
    background-image: url(../images/common/bg_sitemap-01_pc.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 70px 0 50px;

    @include g.mq-down(lg) {
      background-image: url(../images/common/bg_sitemap-01_sp.jpg);
    }

    .inner {
      width: calc(100% - 60px);
      max-width: 1210px;
      margin: 0 auto;

      .logo {
        width: 138.98px;
        height: 72.38px;
        margin: 0 auto 40px;


        @include g.mq-down(lg) {
          width: 108px;
          height: 56.25px;
          margin: 0 auto 50px;
          position: static;
        }
      }

      .content-box {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: stretch;

        @include g.mq-down(lg) {
          display: block;
        }

        .inquiries {
          width: 50%;
          padding-left: 60px;

          @include g.mq-down(lg) {
            width: 100%;
            padding-left: 0;
          }

          .midashi {
            font-size: 2rem;
            margin-bottom: 8px;
            letter-spacing: 3px;
            color: g.$gold;

            @include g.mq-down(lg) {
              text-align: center;
              margin-bottom: 14px;
            }
          }

          .list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @include g.mq-down(lg) {
              display: block;
            }

            li {
              width: calc(50% - 3px);
              margin-top: 6px;

              @include g.mq-down(lg) {
                width: 100%;
                margin-top: 6px;
              }
            }

            &.webform {
              margin-bottom: 40px;

              a {
                .icon {
                  @include g.mq-up(lg) {
                    display: none;
                  }
                }

                &.el_btn_gr {
                  border-color: g.$green;
                }
              }
            }


            &.telephone {
              li {
                color: #fff;

                @include g.mq-down(lg) {
                  text-align: center;
                  border-bottom: 1px solid #fff;
                  padding-bottom: 20px;
                  margin-bottom: 20px;

                  &:nth-last-of-type(1) {
                    border-bottom: none;
                  }
                }
              }

              li:first-child {
                border-right: 1px solid g.$grey;

                @include g.mq-down(lg) {
                  border-right: none;

                }
              }

              li:last-child {
                padding-left: 20px;

                @include g.mq-down(lg) {
                  padding-left: 0;
                }
              }

              .komidashi {
                font-size: 1.2rem;

                @include g.mq-down(lg) {
                  margin-bottom: 10px;
                }
              }

              .number {
                display: block;
                font-size: 1.6rem;
                letter-spacing: 1px;
                margin-bottom: 5px;

                @include g.mq-down(lg) {
                  border: 1px solid #fff;
                  text-align: center;
                  padding: 10px 0;
                  margin-bottom: 10px;
                }

                span {
                  display: inline-block;
                  font-size: 2.1rem;
                }
              }

              .openhour {
                font-size: 1.2rem;
              }
            }
          }
        }

        .sitemap {
          width: 50%;
          padding-right: 60px;
          border-right: 1px solid g.$grey-thin;

          @include g.mq-down(lg) {
            display: none;
            /*
            width: 100%;
            padding-right: 0;
            border-right: 0;
            padding-top: 50px;
            */
          }



          .index {
            display: flex;
            justify-content: space-between;
            font-size: 1.2rem;

            &--sp {
              display: none;
            }

            @include g.mq-down(lg) {
              &--pc {
                display: none;
              }

              &--sp {
                display: block;
                font-size: 1.3rem;
              }
            }

            .list {
              li {
                margin-bottom: 15px;
                color: #fff;
                font-size: 1.2rem;
                letter-spacing: 0;

                @include g.mq-down(lg) {
                  text-align: center;
                }

                &:last-child {
                  margin-bottom: 0;
                }

                &.insta {
                  margin-top: 25px;

                  svg {
                    width: 20px;
                    height: 20px;
                    fill: g.$gold;
                  }
                }
              }
            }
          }

          .corp-nav {
            margin-top: 30px;



            .list {
              display: flex;
              flex-wrap: wrap;
              align-items: center;

              li {
                color: #fff;
                font-size: 1.2rem;
                line-height: 1;

                &.insta {
                  svg {
                    width: 18px;
                    height: 18px;
                    fill: #fff;
                  }

                }

                &:nth-child(n+2) {
                  padding: 0 0 0 15px;

                  &::after {
                    content: "";
                    display: inline-block;
                    width: 1px;
                    height: 10px;
                    background: #fff;
                    margin-left: 15px;
                  }
                }

                &:nth-last-of-type(1) {
                  &::after {
                    content: none;
                  }
                }
              }
            }
          }
        }


      }

      .address {
        color: #fff;
        text-align: center;
        margin-top: 20px;

        @include g.mq-down(lg) {
          margin-top: 40px;
        }

        a {
          display: inline-block;
          padding-bottom: 5px;
          border-bottom: 1px solid #fff;

          svg {
            display: inline-block;
            width: 20px;
            height: 20px;
            fill: #fff;
            margin-left: 8px;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .pcGroupSec {
    background: g.$blue;
    padding: 70px 0 80px;
    color: #fff;

    @include g.mq-down(lg) {
      display: none;
    }

    .inner {
      width: calc(100% - 40px);
      max-width: 1200px;
      margin: 0 auto;

      .midashi {
        margin-bottom: 40px;

        span {
          display: inline-block;
          vertical-align: middle;

          &.en {
            margin-right: 20px;
            font-size: 1.8rem;
            letter-spacing: 1px;
          }

          .icon {
            display: inline-block;
            width: 15px;
            height: 13px;
            margin-left: 10px;
            position: relative;
            top: 1px;
          }
        }
      }

      .contentBox {
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        .col {
          width: calc(31% - min(g.pcvw(50), 50px));
          min-width: 320px;

          &:nth-last-of-type(1) {
            width: calc(37% + min(g.pcvw(50), 50px));
            min-width: none;
            padding-left: min(g.pcvw(200), 200px);
            position: relative;

            &::before {
              position: absolute;
              top: 0;
              left: min(g.pcvw(100), 100px);
              content: "";
              width: 1px;
              height: 100%;
              background: #fff;
            }
          }

          .block {
            margin-bottom: 3rem;
            font-size: 1.2rem;
            line-height: 2.8;

            .komidashi {
              font-weight: 700;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .spGroupSec {
    background: g.$grey;
    padding: 60px 0 80px;
    color: #fff;

    @include g.mq-up(lg) {
      display: none;
    }

    .inner {
      width: calc(100% - 60px);
      margin: 0 auto;

      .list1 {
        text-align: center;
        margin-bottom: 40px;

        li {
          margin-bottom: 15px;

          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }
        }
      }

      .list2 {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;

        li {
          margin: 0 10px 15px;

          &:nth-last-of-type(1) {
            width: 100%;
            margin-bottom: 0;
          }

          .icon {
            width: 15px;
            height: 17px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 10px;

            svg {
              width: 100%;
              height: 100%;
              fill: #fff;
            }
          }
        }
      }
    }
  }

  .copyrightSec {
    background: #fff;
    text-align: center;
    padding: 15px 0;
    line-height: 1;
    letter-spacing: 1px;

    @include g.mq-down(lg) {
      padding: 20px 0 20px;
      font-size: 1.1rem;
    }
  }
}

.fixed_footer_link {
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 5;

  @include g.mq-down(md) {
    width: 100%;
  }

  .btns {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid g.$green;

    /* z-index: 43; */
    a {
      background-color: #4f93a0;
      width: 240px;
      display: block;
      position: relative;
      padding: 20px 0 16px;
      /* width: 50%; */
      color: #fff;
      line-height: 1;
      font-size: 1.5rem;
      text-align: center;

      @include g.mq-down(lg) {
        width: 33.3333%;
        font-size: 1.4rem;
        letter-spacing: 0px;
      }

      span {
        display: inline-block;

        @include g.mq-down(lg) {
          //padding-left: 15px;
        }
      }

      &.tel_link {
        background: #fff;
        border-left: 1px solid g.$green;
        color: g.$green;
        font-weight: 600;

        @include g.mq-up(lg) {
          display: none;
        }

        //&::before {
        //  background-image: url(../img/common/mail-org.svg);
        //}
        i {
          width: 16px;
          display: inline-block;
          margin-top: 2px;
          margin-right: 5px;
          vertical-align: top;
        }
      }

      &.contact_link {
        background: #fff;
        color: g.$green;
        font-weight: 600;



        //&::before {
        //  background-image: url(../img/common/mail-org.svg);
        //}
        i {
          width: 16px;
          display: inline-block;
          margin-top: -2px;
          margin-right: 5px;
          vertical-align: top;

          @include g.mq-down(lg) {
            margin-top: 0;
          }
        }
      }

      &.request_link {
        background: #fff;
        border-right: 1px solid g.$green;
        color: g.$green;
        font-weight: 600;

        @include g.mq-down(lg) {
          display: none;
        }

        ;

        //&::before {
        //  background-image: url(../img/common/shiryo.svg);
        //}
        i {
          width: 20px;
          display: inline-block;
          margin-top: 0px;
          margin-right: 5px;
          vertical-align: top;
        }
      }

      &.reserve_link {
        background: g.$green;
        font-weight: 600;

        i {
          width: 16px;
          display: inline-block;
          margin-top: -2px;
          margin-right: 5px;
          vertical-align: top;

          @include g.mq-down(lg) {
            margin-top: 0;
          }
        }

        //&::before {
        //  background-image: url(../img/common/calendar-wh.svg);
        //}
      }

      &.tel_link {
        background: #fff;
        color: g.$green;

        @include g.mq-up(md) {
          display: none;
        }

        //&::before {
        //  background-image: url(../img/common/tel-org.svg);
        //}
        i {
          width: 16px;
          display: inline-block;
          margin-top: -2px;
          margin-right: 5px;
          vertical-align: top;

          @include g.mq-down(lg) {
            margin-top: 0;
          }
        }
      }

      &::before {
        content: "";
        background-repeat: no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);

        background-position: center;

        @include g.mq-down(md) {
          width: 16px;
          height: 16px;
          left: 10px;
        }
      }
    }
  }
}
