@use "../global" as g;

.p-item {

  .mvSec {
    margin-bottom: 80px;

    @include g.mq-down(lg) {
      margin-bottom: 60px;
    }

    figure {
      width: 100%;
      height: 660px;

      @include g.mq-down(lg) {
        width: 100%;
        height: g.spvw(375);
      }

      @include g.imgFit();
    }
  }

  .outlineSec {
    margin-bottom: 95px;

    @include g.mq-down(lg) {
      margin-bottom: 60px;
    }

    .inner {
      width: calc(100% - 40px);
      max-width: 1200px;
      margin: 0 auto;

      .contentBox {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include g.mq-down(lg) {
          display: block;
        }

        .photo {
          width: 50%;

          @include g.mq-down(lg) {
            width: 100%;
            height: 84vw;
            margin-bottom: 40px;

            figure {
              width: 100%;
              height: 100%;

              @include g.imgFit();
            }
          }
        }

        .text {
          width: 50%;
          padding: 0 60px 0 96px;

          @include g.mq-down(lg) {
            width: 100%;
            padding: 0;
          }

          .catch {
            margin-bottom: 20px;
            font-size: 2rem;
            line-height: 2;
            letter-spacing: 4px;
            color: g.$blue;

            @include g.mq-down(lg) {
              font-size: 1.8rem;
            }
          }

          .read {
            line-height: 1.8;
          }
        }
      }
    }
  }

  .mediaSec {
    padding-bottom: 100px;

    @include g.mq-down(lg) {
      padding-bottom: 70px;
    }

    .inner {
      padding-left: calc(50vw - 600px);
      box-sizing: unset;

      @include g.mq-down(lg) {
        width: calc(100% - 80px);
        margin: 0 auto;
        padding: 0;
      }

      .content-box {

        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        margin-bottom: 100px;

        @include g.mq-down(lg) {
          display: block;
          margin-bottom: 50px;
        }

        .photo {
          width: calc(100% - 540px);
          height: 453px;

          @include g.mq-down(lg) {
            width: calc(100% + 40px);
            height: 69.333vw;
            margin-bottom: 40px;
          }

          figure {
            width: 100%;
            height: 100%;

            @include g.imgFit();
          }
        }

        .midashi {
          margin-bottom: 30px;
          color: g.$blue;

          .en {
            font-size: 2.4rem;
            letter-spacing: 2px;

            @include g.mq-down(lg) {
              font-size: 2.2rem;
            }
          }

          .ja {
            font-size: 1.4rem;
            letter-spacing: 2px;
          }
        }

        .text {
          width: 540px;
          padding-right: 95px;
          box-sizing: border-box;

          @include g.mq-down(lg) {
            width: 100%;
            padding: 0;
          }

          .komidashi {
            color: g.$blue;
            letter-spacing: 1px;
            margin-bottom: 10px;

            .ja {
              display: inline-block;
              padding-left: 10px;
            }
          }

          .catch {
            font-size: 2rem;
            letter-spacing: 4px;
            margin-bottom: 15px;
            color: g.$blue;

            @include g.mq-down(lg) {
              font-size: 1.8rem;
            }
          }

          .read {
            margin-bottom: 30px;
            line-height: 1.8;
            text-align: justify;
          }
        }
      }
    }

    &.reverse {
      .inner {
        padding-right: calc(50vw - 540px);
        padding-left: 0;

        @include g.mq-down(lg) {
          padding-right: 0;
        }

        .content-box {
          flex-direction: inherit;

          .photo {
            @include g.mq-down(lg) {
              margin-left: -40px;
            }
          }

          .text {
            padding-right: 0;
            padding-left: 95px;

            @include g.mq-down(lg) {
              padding-left: 0;
            }
          }
        }
      }
    }
  }

  .beautySec {
    background: g.$yellow-thin;
    padding-top: 130px;

    @include g.mq-down(lg) {
      padding-top: 100px;
    }
  }

  .coordinateSec {
    background: g.$yellow-thin;

    .more {
      text-align: center;
      margin-top: 60px;

      .btn {
        letter-spacing: 2px;
      }

      @include g.mq-down(lg) {
        width: calc(100% - 30px);
        margin: 60px auto 0;
      }
    }
  }

  .flowerSec {
    background: g.$yellow-thin;


  }

  .weddingringSec {
    padding-top: 130px;

    @include g.mq-down(lg) {
      padding-top: 60px;
    }

    .inner {
      .content-box {
        margin-bottom: 0;
      }
    }

  }

  .photomovieSec {
    @include g.mq-down(lg) {
      padding-bottom: 0;
    }
  }

  .recommendfairSec {
    background-image: url(../images/weddingdress/img_recommendfair-01_pc.jpg);

    @include g.mq-down(lg) {
      background-image: url(../images/weddingdress/img_recommendfair-01_sp.jpg);
    }
  }
}
