@use "../global" as g;

.p-inquiryTemplate {

  .telcontactSec {
    margin-bottom: 60px;
    margin-top: -40px;

    @include g.mq-down(lg) {
      margin-top: -20px;
      margin-bottom: 40px;
    }

    .inner {
      text-align: center;
      line-height: 2;

      @include g.mq-down(lg) {
        font-size: 1.2rem;
      }
    }
  }

  .inquiryFormSec {
    padding-bottom: 120px;

    @include g.mq-down(lg) {
      padding-bottom: 80px;
    }

    .inner {
      width: calc(100% - 40px);
      max-width: 980px;
      margin: 0 auto;
      padding: 0px 6% 0px;
      box-sizing: border-box;

      @include g.mq-down(lg) {
        width: 100%;
        padding: 0px 20px;
      }

      .title {
        color: g.$blue;
        letter-spacing: 2px;
        text-align: center;
        margin-bottom: 30px;

        @include g.mq-down(lg) {
          margin-bottom: 20px;
        }

        .en {
          font-size: 2.8rem;
          font-weight: 700;
          margin-bottom: 15px;

          @include g.mq-down(lg) {
            font-size: 2rem;
            margin-bottom: 10px;
          }
        }
      }

      .read {
        line-height: 1.8;
        text-align: center;
        margin-bottom: 30px;

        a {
          text-decoration: underline;

          @include g.mq-up(lg) {
            pointer-events: none;
            text-decoration: none;
          }
        }
      }
    }
  }

  .intro {
    margin-bottom: 30px;

    p {
      line-height: 1.8;
      text-align: center;

      a {
        color: g.$blue;
        text-decoration: underline;
      }
    }

    .notes {
      margin-top: 20px;

      p {
        text-align: left;
        margin-bottom: 0;

        @include g.mq-down(lg) {
          text-indent: -1em;
          margin-left: 1em;
        }
      }
    }
  }

  //フォームスタイリング
  .form-box {

    .flow-box {
      margin-bottom: 30px;

      ul {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        @include g.mq-down(lg) {
          justify-content: space-between;
        }

        li {
          width: 32%;
          max-width: 200px;
          padding: 15px 0;
          background: #CCC;
          color: g.$blue;
          font-weight: 100;
          text-align: center;

          @include g.mq-down(lg) {
            line-height: 1.6;
            width: 28%;
            font-size: 13px;
          }

          &.deco {
            width: 20px;
            height: 20px;
            margin: 0 7px;
            padding: 0;
            box-sizing: border-box;
            position: relative;
            background: transparent;

            .icon {
              display: inline-block;
              width: 20px;
              height: 20px;

              svg {
                fill: g.$blue;
              }
            }
          }

          &.current {
            background-color: g.$blue;
            color: #ffffff;
            font-weight: 100;
          }
        }
      }
    }

    .must {
      color: g.$green;
    }

    .form,
    .mw_wp_form {
      margin: 30px 0;

      @include g.mq-down(lg) {
        margin: 30px 0 0;
      }
    }

    dl {
      display: flex;
      line-height: 1.6;
      margin-bottom: 10px;

      @include g.mq-down(lg) {
        display: block;
      }

      dt {
        width: 170px;
        padding-top: 6px;
        letter-spacing: 1px;

        @include g.mq-down(lg) {
          width: 100%;
          margin-bottom: 10px;
        }
      }

      dd {
        width: calc(100% - 170px);
        letter-spacing: 0px;

        @include g.mq-down(lg) {
          width: 100%;
        }

        .param {
          padding-top: 6px;
        }

        .wrap {
          margin-bottom: 5px;
          width: 100%;
          height: 30px;

          @include g.mq-down(lg) {
            height: auto;
            display: flex;
            justify-content: flex-start;
          }

          &:nth-of-type(3) {
            margin-bottom: 0;
          }

          .block {
            display: inline-block;
            margin-right: 4px;
            vertical-align: top;

            @include g.mq-down(lg) {
              width: 48%;
              margin-right: 0;
              margin-bottom: 5px;
              display: flex;

              align-items: center;
            }

            span {
              width: 33px;

              @include g.mq-down(lg) {}
            }

            .adjust {
              @include g.mq-up(md) {
                position: relative;
                top: -10px;
              }
            }

            .input {
              @include g.mq-down(lg) {
                width: calc(100% - 2.5em);
                font-size: 1.5rem;
              }
            }
          }


        }

        .radio {
          margin-top: 5px;
          margin-bottom: 10px;

          span {
            display: inline-block;
            margin-right: 80px;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        small {
          display: block;
          margin-top: 10px;
        }

        span {
          display: inline-block;

          &.error {
            display: block !important;
          }
        }

        &>ol {
          &>li {
            margin-bottom: 5px !important;
            display: flex;
            align-items: center;

            span {
              margin-right: 5px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .selectbox {
      overflow: hidden;
    }

    .selectbox select {
      width: 100%;
      padding-right: 1em;
      cursor: pointer;
      text-indent: 0.01px;
      text-overflow: ellipsis;
      padding-right: 38px;
      color: g.$text-color;
      border: none;
      outline: none;
      background: transparent;
      background-image: none;
      box-shadow: none;
      -webkit-appearance: none;
      appearance: none;

      @include g.mq-down(lg) {
        font-size: 1.4rem;
      }
    }

    .selectbox select::-ms-expand {
      display: none;
    }

    .selectbox::before {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 10px;
      width: 0;
      height: 0;
      padding: 0;
      content: '';
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid g.$text-color;
      pointer-events: none;
    }

    .input {
      width: 354px;
      border-radius: 3px;
      border: 1px solid #b5b5b5;
      box-sizing: border-box;
      background: transparent;
      text-indent: 0.01px;
      text-overflow: ellipsis;
      padding: 6px;

      @include g.mq-down(lg) {
        width: 80%;
        padding: 18px 6px;
        font-size: 1.6rem;
      }

      &::placeholder {
        color: #b5b5b5;
      }

      &.medium {
        width: 102%;
      }

      &.short {
        width: 140px;
      }

      &.long {
        width: 100%;
      }
    }

    .selectbox {
      position: relative;
      display: inline-block;
      padding: 4px 6px;

      @include g.mq-down(lg) {
        padding: 14px 6px;
      }
    }

    .textarea {
      border: 1px solid #b5b5b5;
      border-radius: 3px;
      width: 100%;
      box-sizing: border-box;
      background: transparent;
      padding: 10px;

      @include g.mq-down(lg) {
        font-size: 1.5rem;
      }

      &::placeholder {
        color: #b5b5b5;
      }
    }

    .privacy-box {
      border: 1px solid #b3b3b3;
      border-radius: 5px;
      padding: 25px;
      height: 140px;
      overflow-y: scroll;
      overflow-x: hidden;
      margin-bottom: 30px;
      line-height: 1.8;
      margin-top: 40px;

      @include g.mq-down(lg) {
        padding: 20px;
      }

      .catch {
        font-weight: 700;
        font-size: 1.5rem;
        margin-bottom: 20px;
      }

      .bold {
        font-weight: 700;
      }

      ul li {
        list-style-type: decimal;
        margin-left: 1em;
      }
    }

    .check-box {
      text-align: center;
    }

    .btns {
      margin-top: 60px;

      @include g.mq-down(lg) {
        margin-top: 30px;
      }

      .btn {
        display: block;
        padding: 20px 0;
        width: 310px;
        margin: 0 auto;
        text-align: center;
        background: g.$green;
        border: none;
        color: #FFFFFF;
        font-weight: 700;

        @include g.mq-up(md) {
          cursor: pointer;
          font-weight: 700;
          @include g.image-link();
        }

        @include g.mq-down(lg) {
          width: 100%;
        }
      }

      .backbtn {
        display: block;
        background: #CCCCCC;
        width: 310px;
        margin: 0 auto;
        padding: 10px 0;
        margin: 20px auto;
        cursor: pointer;
        border: none;

        @include g.mq-up(md) {
          @include g.image-link();
        }
      }
    }
  }

  //フォームスタイリング確認画面
  table#mfp_confirm_table tr th {
    width: 100px !important;
  }

  #mfp_phase_confirm h4 {
    font-size: 2.4rem !important;
    padding: 20px 0 !important;
    margin-bottom: 20px;
    border-bottom: 1px solid g.$blue;
  }

  #mfp_phase_confirm #mfp_confirm_table .mfp_achroma {
    background: transparent;
  }

  #mfp_phase_confirm #mfp_confirm_table .mfp_colored {
    background: transparent;
  }

  #mfp_phase_confirm #mfp_confirm_table tr td,
  #mfp_phase_confirm #mfp_confirm_table tr th {
    padding: 10px 0;
    border-top: none;
  }

  #mfp_phase_confirm #mfp_confirm_table tr td {
    font-weight: 700;
  }

  //各問い合わせ完了画面
  .thanksMessage {
    padding: 50px 0 50px;
    width: 100%;

    .copy {
      color: g.$blue;
      font-size: 2.6rem;
      font-weight: 700;
      line-height: 1.6;
      text-align: center;
      margin-bottom: 40px;

      @include g.mq-down(lg) {
        font-size: 2.4rem;
      }
    }

    .read {
      line-height: 1.8;
      text-align: center;

      @include g.mq-down(lg) {
        text-align: left;
      }
    }

    .backhome {
      display: block;
      text-align: center;
      color: g.$blue;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    #mfp_thanks {
      display: none !important;
    }
  }

  .iconWrap {
    position: relative;
    display: inline-block;

    .icon {
      width: 14px;
      height: 14px;
      position: absolute;
      top: 7px;
      right: 5px;
      z-index: -1;

      svg {
        fill: g.$blue;
      }
    }
  }
}
