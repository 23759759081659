@use "../global" as g;


.p-spaceSingleTemplate {
  .mvSec {
    figure {
      width: 100%;
      height: 660px;
      margin-bottom: 90px;

      @include g.mq-down(lg) {
        width: 100%;
        height: g.spvw(375);
      }

      @include g.imgFit();
    }
  }

  .outlineSec {
    position: relative;
    padding-bottom: 100px;

    .inner {
      padding-left: calc(50vw - 1080px / 2);
      position: relative;

      @include g.mq-down(lg) {
        padding-left: 0;
        width: 100%;
      }



      .content-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 210px;

        @include g.mq-down(lg) {
          display: block;
          width: calc(100% - 80px);
          margin: 0 auto 60px;
        }

        .text {
          width: 414px;
          padding-bottom: 120px;

          @include g.mq-down(lg) {
            width: 100%;
            padding-left: 0;
            padding-bottom: 40px;

          }
        }

        .midashi {
          color: g.$blue;
          margin-bottom: 30px;

          .en {
            letter-spacing: 2px;
            font-size: 1.8rem;
          }
        }

        .catch {
          letter-spacing: 4px;
          font-size: 2rem;
          line-height: 2;
          color: g.$blue;

          @include g.mq-down(lg) {
            font-size: 1.8rem;
            margin-bottom: 20px;

          }
        }

        .read {
          width: min(g.pcvw(720), 720px);
          line-height: 2;
          margin-bottom: 20px;

          @include g.mq-down(lg) {
            width: 100%;
          }
        }

        .spec {
          display: flex;

          @include g.mq-down(lg) {
            display: block;
          }

          dt {
            color: g.$blue;
            font-weight: 700;
            margin-right: 80px;
            letter-spacing: 1px;
          }

          dd {
            span {
              display: inline-block;

              &:first-child {
                margin-right: 60px;
              }
            }
          }
        }

        .photo {
          width: calc(100% - 414px);

          @include g.mq-down(lg) {
            width: 100%;
          }

          .moviewrap {
            width: min(g.pcvw(880), 880px);
            height: min(g.pcvw(500), 500px);
            overflow: hidden;
            margin-bottom: 40px;
            position: relative;

            @include g.mq-down(lg) {
              width: calc(100% + 60px);
              height: g.spvw(202);
              margin-left: -20px;
            }

            .movie {
              position: absolute;
              z-index: -1;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
              height: 100%;
              min-height: 100%;
              min-width: 100%;
            }
          }
        }
      }
    }

    .slides {
      @include g.mq-down(lg) {
        margin: 0px;
      }

      .slide {
        figure {
          margin-bottom: 20px;
        }
      }
    }

    .more {
      margin-top: 60px;

      @include g.mq-down(lg) {
        display: inline-block;
        margin: 40px 20px 0;
      }
    }
  }

  .styleSec {
    padding: 100px 0;
    background: g.$yellow-thin;

    @include g.mq-down(lg) {
      padding: 60px 0;
    }

    .inner {
      padding: 0 20px;

      .midashi {
        color: g.$blue;
        margin-bottom: 40px;

        .en {
          font-size: 1.8rem;
          letter-spacing: 3px;
        }
      }

      .list {
        .card {
          figure {
            width: 100%;
            height: 22vw;
            max-height: 246px;
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              height: 56vw;
              max-height: none;
            }

            @include g.imgFit();
          }

          .caption {
            .title {
              font-size: 1.6rem;
              letter-spacing: 2px;
              color: g.$blue;
              margin-bottom: 10px;
            }

            .more {
              margin-top: 20px;

              a {
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }

  .otherchapelSec {
    @include g.mq-down(lg) {
      padding-bottom: 30px;
    }

    .inner {
      padding-right: calc(50vw - 1000px / 2);

      @include g.mq-down(lg) {
        width: calc(100% - 40px);
        margin: 0 auto;
        padding: 0;
      }

      .box {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include g.mq-down(lg) {
          display: block;
        }

        .photo {
          width: calc(g.pcvw(847));
          height: 440px;

          @include g.mq-down(lg) {
            width: 100%;
            height: g.spvw(255);
            margin-bottom: 30px;
          }

          figure {
            width: 100%;
            height: 100%;
            @include g.imgFit();
          }
        }

        .text {
          width: min(g.pcvw(360), 360px);
          padding-left: min(g.pcvw(140), 140px);

          @include g.mq-down(lg) {
            width: 100%;
            padding: 0 0 0 20px;
          }

          .nakamidashi {
            color: g.$blue;
            margin-bottom: 30px;
            //padding-left: 20px;

            .en {
              font-size: 1.8rem;
              letter-spacing: 2px;
              margin-bottom: 3px;
            }
          }

          .btn {
            @include g.mq-down(lg) {
              width: calc(100% - 40px);
              // margin: 0 0 0 20px;
            }

            span {
              margin: 0 20px 0 0;
              width: 100%;
            }
          }
        }

        .midashi {
          color: g.$blue;
          letter-spacing: 2px;
          font-weight: 700;
          margin-bottom: 30px;
        }
      }
    }
  }

}

.p-bluebijou {
  .recommendfairSec {
    background-image: url(../images/bluebijou/img_recommendfair-01_pc.jpg);

    @include g.mq-down(lg) {
      background-image: url(../images/bluebijou/img_recommendfair-01_sp.jpg);
    }
  }
}

.p-ariose {
  .recommendfairSec {
    background-image: url(../images/ariose/img_recommendfair-01_pc.jpg);

    @include g.mq-down(lg) {
      background-image: url(../images/ariose/img_recommendfair-01_sp.jpg);
    }
  }
}
