@use "../global" as g;

//slick default style

.slick-dots {
  //bottom: -30px !important;
}

.slick-dots li {
  margin: 0 5px !important;
  width: 8px !important;
  height: 8px !important;


  button {
    width: 8px !important;
    height: 8px !important;
  }

  button:before {
    top: 0 !important;
    left: 0 !important;
    display: block;
    font-family: sans-serif !important;
    content: "" !important;
    color: g.$text-color !important;
    font-weight: 700;
    opacity: 1 !important;
    width: 8px !important;
    height: 8px !important;
    background-color: #CCC8BB;
    border-radius: 50%;
    border: 1px solid #CCC8BB;

    @include g.mq-down(md) {
      width: 10px !important;
      height: 10px !important;
    }

  }

  &.slick-active button:before {
    display: block;
    content: "" !important;
    background-color: g.$blue !important;
    border: 1px solid g.$blue !important;
  }
}

.slick-loading .slick-list {
  background: none !important;
}

.slick-prev:before,
.slick-next:before {
  content: "" !important;
  background-image: url(../images/common/btn_slidearrow.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 30px;
  height: 30px;
  display: block;
  opacity: 1 !important;


}

.slick-prev:before {
  transform: rotate(-180deg);
}

.slick-prev,
.slick-next {
  width: 30px !important;
  height: 30px !important;
  z-index: 1;


}

// slick スライドコントロールスタイル

.control-style1 {

  .slick-prev {
    left: 0;
    top: calc(100% + 40px);

    @include g.mq-down(lg) {
      left: 0px;
    }
  }

  .slick-next {
    left: 40px;
    right: auto;
    top: calc(100% + 40px);

    @include g.mq-down(lg) {
      right: 20px;
    }
  }

}

.control-dot-style1 {
  .slick-dots {
    bottom: -50px;
    left: 86px;
    text-align: left;
  }

}
