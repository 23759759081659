@use "../global" as g;

.p-about {
  background: g.$off-white;

  // header {
  //   &::after {
  //     background: transparent;
  //   }
  // }

  main {
    margin-top: 0;
  }

  .mvSec {
    width: 100%;
    height: 100vh;
    background-image: url(../images/about/img_mv-01.jpg);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-bottom: 160px;

    @include g.mq-down(lg) {
      height: g.spvw(570);
      margin-bottom: 100px;
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(#000000, 0.2);
      z-index: 0;
    }

    .midashi {
      width: 100%;
      color: #fff;
      text-align: center;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .en {
        font-size: 3.6rem;
        letter-spacing: 4px;

        @include g.mq-down(lg) {
          font-size: 3rem;
        }
      }

      .ja {
        font-size: 1.4rem;
        letter-spacing: 2px;
      }
    }

  }

  .locationSec {
    margin-bottom: 130px;
    position: relative;

    &::before {
      content: "";
      width: 100%;
      height: 902px;
      display: block;
      position: absolute;
      top: 80px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      background-image: url(../images/about/bg_location-01.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @include g.mq-down(lg) {
        top: g.spvw(45);
      }
    }

    .midashi {
      padding-left: calc(50vw - 1200px / 2);
      width: 645px;
      margin-bottom: 90px;
      box-sizing: content-box;

      @include g.mq-down(lg) {
        width: g.spvw(335);
        padding-left: 0;
        margin: 0 auto 40px;
      }
    }

    .box {
      .text {
        .catch {
          font-size: 2.2rem;
          letter-spacing: 4px;
          line-height: 1.8;
          margin-bottom: 30px;

          @include g.mq-down(lg) {
            font-size: 1.6rem;
            margin-bottom: 20px;
          }
        }

        .read {
          line-height: 2;

          @include g.mq-down(lg) {
            font-size: 1.3rem;
          }

          span {
            display: block;
            padding-bottom: 10px;
          }
        }
      }

      &.locationbox {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        padding-left: calc(50vw - 1000px / 2);
        margin-bottom: 130px;

        @include g.mq-down(lg) {
          display: block;
          padding-left: 0;
          margin-bottom: 70px;
        }

        .photo {
          .moviewrap {
            width: min(g.pcvw(750), 750px);
            height: min(g.pcvw(430), 430px);
            overflow: hidden;
            position: relative;

            @include g.mq-down(lg) {
              width: calc(100% - 40px);
              height: g.spvw(192);
              margin-left: 40px;
              margin-bottom: 30px;
            }

            .movie {
              position: absolute;
              z-index: -1;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
              height: 00%;
              min-height: 100%;
              min-width: 100%;

              @include g.mq-down(lg) {
                height: calc(100% + 3px);
              }
            }
          }
        }

        .text {
          width: 433px;
          padding-right: 140px;

          @include g.mq-down(lg) {
            width: 100%;
            padding-right: 0;
            padding-left: 40px;
          }

          .nakamidashi {
            width: min(g.pcvw(268), 268px);
            margin-bottom: 54px;

            @include g.mq-down(lg) {
              width: 188px;
              margin-bottom: 40px;
            }
          }
        }
      }

      &.viewbox {
        padding-right: calc(10vw - 1200px / 6);
        display: flex;
        align-items: center;
        margin-bottom: 70px;

        @include g.mq-down(lg) {
          padding-right: 0;
          display: block;
        }

        .photo {
          width: g.pcvw(740);
          height: 410px;

          @include g.mq-down(lg) {
            width: 100%;
            height: g.spvw(227);
          }

          figure {
            width: 100%;
            height: 100%;
            @include g.imgFit(50%, 100%);
          }
        }

        .text {
          width: 543px;
          padding-left: min(g.pcvw(119), 119px);

          @include g.mq-down(lg) {
            width: 100%;
            padding: 40px 40px 0;
          }

          .catch {
            font-size: 2.4rem;

            @include g.mq-down(lg) {
              font-size: 1.6rem;
            }
          }
        }
      }
    }

    .slides {
      margin-left: calc(50vw - 1080px / 2);

      @include g.mq-down(lg) {
        margin-left: 40px;
      }

      .slide {
        margin-right: 20px;

        @include g.mq-down(lg) {
          margin-right: 10px;
        }

        figure {
          width: 590px;
          height: 340px;

          @include g.mq-down(lg) {
            width: g.spvw(260);
            height: g.spvw(260);
          }

          &.half {
            width: 295px;

            @include g.mq-down(lg) {
              width: g.spvw(260);
            }
          }

          @include g.imgFit();
        }
      }

      .slick-dots {
        text-align: left;
      }

      .slick-prev {
        left: -30px;
      }

      .slick-next {
        right: 10px;
      }
    }

  }

  .weddingstageSec {
    margin-bottom: 100px;

    .inner {
      padding-left: calc(50vw - 748px / 2);

      @include g.mq-down(lg) {
        padding: 0 40px;
      }

      .bgwrap {
        position: relative;
        padding-bottom: 80px;

        @include g.mq-down(lg) {
          padding-bottom: 70px;
        }

        .moviewrap {
          display: block;
          position: absolute;
          top: 0;
          left: calc((-50vw - 748px / 2));
          z-index: -1;
          width: calc(100% + 883px);
          height: 100%;
          overflow: hidden;

          .movie {
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% + 1000px);
            height: calc(100% + 1000px);
            min-height: 100%;
            min-width: 100%;
            opacity: 0.15;

            @include g.mq-down(lg) {
              height: calc(100% + 3px);
            }
          }
        }
      }

      .imgwrap {
        width: 100%;
        height: min(g.pcvw(582), 582px);
        overflow: hidden;
        position: relative;
        margin-bottom: 60px;

        @include g.mq-down(lg) {
          width: calc(100% + 60px);
          height: g.spvw(355);
          margin-left: -20px;
          margin-bottom: 40px;
        }

        figure {
          position: absolute;
          z-index: -1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: auto;

          @include g.mq-down(lg) {
            width: calc(100% + 40px);
          }

          @include g.imgFit(50%, 100%);


        }

        .midashi {
          position: absolute;
          left: 0px;
          bottom: 45px;
          width: 627px;

          @include g.mq-down(lg) {
            width: g.spvw(335);
            bottom: 20px;
          }
        }
      }

      .catch {
        font-size: 1.8rem;
        letter-spacing: 4px;
        line-height: 2.2;
        margin-bottom: 20px;

        @include g.mq-down(lg) {
          font-size: 1.6rem;
          letter-spacing: 3px;
        }
      }

      .read {
        line-height: 2.2;

        span {
          display: block;
          padding-bottom: 10px;
        }
      }

      .stagemap {
        width: calc(100% + 143px);
        max-width: 1200px;
        margin-bottom: 56px;

        @include g.mq-down(lg) {
          width: calc(100% + 80px);
          margin-left: -40px;
        }
      }

      .otherstage {
        .list {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          width: min(g.pcvw(915), 915px);

          @include g.mq-down(lg) {
            display: block;
            width: 100%;
          }

          .item {
            width: min(g.pcvw(436), 436px);
            margin-bottom: 50px;

            @include g.mq-down(lg) {
              width: 100%;
              margin-bottom: 60px;

              &:nth-last-of-type(1) {
                margin-bottom: 0;
              }
            }

            figure {
              margin-bottom: 25px;

              @include g.mq-down(lg) {
                margin-bottom: 20px;
              }
            }

            .komidashi {
              font-size: 2rem;
              letter-spacing: 2px;
              color: g.$blue;
              margin-bottom: 5px;
            }

            .read {
              margin-bottom: 40px;
            }

            .select {
              font-weight: 700;
              font-size: 1.3rem;
              color: g.$blue;
              line-height: 2;
              letter-spacing: 2px;
              margin-top: -30px;
              margin-bottom: 50px;

              @include g.mq-down(lg) {
                margin-bottom: 30px;
              }

              a {
                display: inline-block;

                &:nth-of-type(3),
                &:nth-last-of-type(1) {
                  &::after {
                    content: none;
                  }

                }

                &::after {
                  content: "|";
                  display: inline-block;
                  margin: 0 8px;
                  position: relative;
                  top: -2px;
                }
              }
            }

            &:nth-last-of-type(1) {
              .select {
                margin-bottom: 24px;
              }
            }
          }
        }
      }
    }
  }

  .weddingstorySec {
    margin-bottom: 140px;

    @include g.mq-down(lg) {
      margin-bottom: 100px;
    }

    .inner {
      width: calc(50vw + 540px);
      height: 586px;
      background-image: url(../images/about/img_weddingstory-01_pc.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;

      @include g.mq-down(lg) {
        background-image: url(../images/about/img_weddingstory-01_sp.jpg);
        width: 100%;
        height: 100vw;
      }

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(#000000, 0.4);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
      }

      .box {
        padding-left: calc(50vw - 1000px / 2);
        position: absolute;
        bottom: 60px;
        left: 0;
        z-index: 1;
        color: #fff;

        @include g.mq-down(lg) {
          width: 100%;
          padding: 0 40px;
          top: 50%;
          left: 50%;
          bottom: auto;
          transform: translate(-50%, -50%);

        }

        .midashi {
          width: 482px;
          margin-bottom: 30px;

          @include g.mq-down(lg) {
            width: 100%;
            margin-bottom: 10px;
          }
        }

        .read {
          font-size: 1.6rem;
          letter-spacing: 4px;
          line-height: 2;
          margin-bottom: 40px;
        }

        .btn {
          width: 260px;
          font-size: 1.2rem;

          @include g.mq-down(lg) {
            width: 100%;
          }
        }
      }
    }
  }

  .staffSec {
    background: linear-gradient(180deg, transparent 0%, transparent 125px, g.$yellow-thin 125px, g.$yellow-thin 100%);
    padding-bottom: 120px;

    @include g.mq-down(lg) {
      background: linear-gradient(180deg, transparent 0%, transparent calc(g.spvw(248) + 60px), g.$yellow-thin calc(g.spvw(248) + 60px), g.$yellow-thin 100%);
    }

    .box {
      display: flex;
      align-items: flex-end;
      padding-right: calc(50vw - 1000px / 2);
      margin-bottom: 80px;

      @include g.mq-down(lg) {
        display: block;
        margin-bottom: 60px;
      }

      .photo {
        width: g.pcvw(625);
        height: 437px;

        @include g.mq-down(lg) {
          width: calc(100% - 20px);
          height: g.spvw(248);
          margin-bottom: 40px;
        }

        figure {
          width: 100%;
          height: 100%;
          @include g.imgFit();
        }
      }

      .text {
        width: 558px;
        padding-left: min(g.pcvw(107), 107px);

        @include g.mq-down(lg) {
          width: 100%;
          padding: 0 20px;
        }

        .midashi {
          width: 308px;
          margin-bottom: 50px;

          @include g.mq-down(lg) {
            width: 268px;
            margin-bottom: 30px;
          }
        }

        .catch {
          font-size: 2rem;
          letter-spacing: 4px;
          margin-bottom: 30px;

          @include g.mq-down(lg) {
            font-size: 1.6rem;
            line-height: 2;
            margin-bottom: 20px;
          }
        }

        .read {
          line-height: 2;
          font-size: 1.3rem;
          letter-spacing: 2px;
        }
      }
    }

    .slides {
      margin-left: calc(50vw - 550px);

      @include g.mq-down(lg) {
        margin-left: 20px;
      }

      .slide {
        .wrap {
          width: 320px;
          margin-right: 45px;

          @include g.mq-down(lg) {
            width: g.spvw(315);
            margin-right: g.spvw(20);
          }

          figure {
            width: 100%;
            margin-bottom: 13px;
          }

          .job {
            margin-bottom: 10px;

            .en {
              display: block;
              font-size: 1.2rem;
              font-weight: 700;
              color: g.$blue;
              letter-spacing: 1px;
              margin-bottom: 3px;
            }

            .ja {
              display: block;
              font-size: 1.6rem;
              letter-spacing: 2px;
            }
          }

          .read {
            text-align: justify;
          }

        }
      }
    }
  }

  .contentsSec {
    padding: 105px 0 150px;

    @include g.mq-down(lg) {
      padding: 100px 0 100px;
    }

    .inner {
      padding-left: calc(50vw - 1080px / 2);

      @include g.mq-down(lg) {
        padding-left: 20px;
      }

      .midashi {
        font-size: 2.5rem;
        color: g.$blue;
        letter-spacing: 2px;
        margin-bottom: 50px;

        @include g.mq-down(lg) {
          margin-bottom: 40px;
        }
      }

      .slides {
        .slide {
          margin-right: 28px;

          @include g.mq-down(lg) {
            margin-right: g.spvw(20);
          }

          .item {
            width: 245px;

            @include g.mq-down(lg) {
              width: g.spvw(245);
            }

            figure {
              margin-bottom: 15px;
            }

            .title {
              color: g.$blue;
              text-align: center;

              .en {
                font-size: 1.5rem;
                margin-bottom: 5px;
                letter-spacing: 2px;
              }

              .ja {
                font-size: 1.3rem;
              }
            }
          }

        }
      }
    }
  }
}
