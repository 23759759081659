@use "../global" as g;

.commonreportSec {
  padding: 60px 0 80px;
  background: g.$yellow-thin;

  .inner {
    width: calc(100% - 60px);
    max-width: 1140px;
    margin: 0 auto;

    .midashi {
      color: g.$blue;
      text-align: center;
      margin-bottom: 40px;

      .en {
        font-size: 3rem;
        letter-spacing: 4px;

        @include g.mq-down(lg) {
          font-size: 2.4rem;
        }
      }
    }

    .slides {

      .slide {

        a {
          display: block;
          width: 246px;

          @include g.mq-down(lg) {
            width: calc(100vw - 80px);
          }

          figure {
            margin: 0;
            width: 100%;
            height: 18.009vw;
            max-height: 246px;

            @include g.imgFit();

            @include g.mq-down(lg) {
              height: 56vw;
            }
          }

          .title {
            color: g.$blue;
            letter-spacing: 3px;
            background: #fff;
            text-align: center;
            padding: 12px 0;
          }
        }
      }
    }

    .more {
      text-align: center;
      margin-top: 50px;
    }
  }
}
