@use "../global" as g;

.pageHeadSec {
  padding-top: 120px;

  @include g.mq-down(lg) {
    padding-top: 60px;
  }

  .inner {
    width: calc(100% - 40px);
    max-width: 1080px;
    margin: 0 auto;

  }

  .head {
    text-align: center;
    margin: 0 0 80px;

    @include g.mq-down(lg) {
      margin: 0 0 50px;
    }

    .en {
      font-size: 3.6rem;
      letter-spacing: 2px;
      color: g.$blue;
      margin-bottom: 10px;

      @include g.mq-down(lg) {
        font-size: 3rem;
      }
    }

    .ja {
      color: g.$blue;
      font-size: 1.2rem;
    }
  }
}
