@use "../global" as g;

.p-cuisine {

  .mvSec {
    margin-bottom: 130px;

    @include g.mq-down(lg) {
      margin-bottom: 60px;
    }

    figure {
      width: 100%;
      height: 660px;

      @include g.mq-down(lg) {
        width: 100%;
        height: g.spvw(375);
      }

      @include g.imgFit();
    }
  }

  .outlineSec {
    position: relative;
    margin-bottom: 148px;

    @include g.mq-down(lg) {
      margin-bottom: 70px;
    }

    &::after {
      content: "";
      display: block;
      width: calc(50vw + 540px + 60px);
      height: calc(100% - 300px - 150px);
      background: g.$off-white;
      position: absolute;
      top: 150px;
      left: 0;
      z-index: -1;
    }

    .inner {
      width: calc(100% - 80px);
      max-width: 1080px;
      margin: 0 auto 134px;

      @include g.mq-down(lg) {
        margin-bottom: 100px;
      }

      .content-box {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;

        @include g.mq-down(lg) {
          display: block;
        }

      }

      .text {
        width: 50%;
        padding-left: 95px;

        @include g.mq-down(lg) {
          width: 100%;
          padding-left: 0;
          margin-bottom: 40px;
        }

        figure {
          height: 460px;
          margin-bottom: 60px;

          @include g.mq-down(lg) {
            width: 100%;
            margin-bottom: 40px;
          }

          @include g.imgFit();
        }

        .midashi {
          color: g.$blue;
          letter-spacing: 2px;
          font-size: 1.8rem;
          margin-bottom: 15px;

          @include g.mq-down(lg) {
            font-size: 1.6rem;
          }
        }

        .catch {
          color: g.$blue;
          letter-spacing: 3px;
          font-size: 2.1rem;
          line-height: 2;
          margin-bottom: 20px;

          @include g.mq-down(lg) {
            font-size: 1.8rem;
          }
        }

        .read {
          line-height: 2;
        }
      }

      .photo {
        width: 50%;

        @include g.mq-down(lg) {
          width: 100%;
        }

        .img1 {
          margin-left: -60px;
          width: calc(100% + 60px);
          margin-bottom: 20px;
          height: 380px;

          @include g.mq-down(lg) {
            margin-left: -40px;
            width: calc(100% + 40px);
            height: g.spvw(216);
            margin-bottom: 10px;
          }

          @include g.imgFit();
        }

        .img2 {
          margin-left: -60px;
          width: calc(100% + 60px);
          height: 380px;

          @include g.mq-down(lg) {
            margin-left: -40px;
            width: calc(100% + 40px);
            height: g.spvw(216);
          }

          @include g.imgFit();
        }
      }
    }

    .slides {
      margin-left: calc(50vw - 1080px / 2);

      @include g.mq-down(lg) {
        margin-left: 40px;
      }
    }
  }

  .mediaSec {

    .content-box {
      display: flex;
      align-items: center;
      padding-right: calc(50vw - 550px);

      @include g.mq-down(lg) {
        display: block;
      }

      .photo {
        width: 64%;
        height: 510px;
        display: flex;
        justify-content: space-between;
        gap: 6px;

        @include g.mq-down(lg) {
          width: calc(100% - 30px);
          height: 80vw;
          margin-bottom: 40px;
        }

        figure {
          width: 50%;
          height: 100%;
          @include g.imgFit();
        }
      }

      .text {
        padding-left: 120px;
        max-width: 413px;

        @include g.mq-down(lg) {
          padding-left: 0;
          width: calc(100% - 80px);
          margin: 0 auto;
        }

        .midashi {
          color: g.$blue;
          margin-bottom: 15px;

          .en {
            font-size: 1.8rem;
            letter-spacing: 2px;
            margin-bottom: 3px;
          }
        }

        .catch {
          color: g.$blue;
          font-size: 2rem;
          letter-spacing: 5px;
          line-height: 2;
          margin-bottom: 20px;

          @include g.mq-down(lg) {
            font-size: 1.8rem;
          }
        }

        .read {
          line-height: 2;
        }
      }
    }

    &.reverse {
      .content-box {
        padding-left: calc(50vw - 550px);
        padding-right: 0;
        flex-direction: row-reverse;

        @include g.mq-down(lg) {
          padding-left: 30px;
        }

        .photo {
          @include g.mq-down(lg) {
            width: calc(100% + 30px);
          }
        }

        .text {
          padding-right: 95px;
          padding-left: 0;

          @include g.mq-down(lg) {
            padding: 0 30px 0 0;
            width: 100%;
            margin: 0 auto;
            max-width: none;


          }
        }
      }
    }

    .slides {
      margin-left: calc(50vw - 1080px / 2);

      @include g.mq-down(lg) {
        margin-left: 40px;
      }
    }
  }

  .featureSec {
    margin-bottom: 184px;

    @include g.mq-down(lg) {
      margin-bottom: 60px;
    }
  }

  .sweetsSec {
    padding-bottom: 120px;

    @include g.mq-down(lg) {
      padding-bottom: 100px;
    }

    .content-box {
      margin-bottom: 100px;

      @include g.mq-down(lg) {
        margin-bottom: 60px;
      }
    }

    .more {
      margin: 60px 0 0;
      padding-left: calc(50vw - 1080px / 2);

      .btn {
        letter-spacing: 2px;
      }

      @include g.mq-down(lg) {
        width: calc(100% - 80px);
        margin: 50px auto 0;
      }
    }
  }

  .recommendfairSec {
    background-image: url(../images/cuisine/img_recommendfair-01_pc.jpg);

    @include g.mq-down(lg) {
      background-image: url(../images/cuisine/img_recommendfair-01_sp.jpg);
    }
  }


}
