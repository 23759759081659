@use "../global" as g;


.menu-btn {
  width: 100px;
  height: 100px;
  //padding: 20px 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include g.mq-down(lg) {
    width: 75px;
    height: 75px;
  }

  // .menu-trigger,
  // .menu-trigger span {
  //   display: inline-block;
  //   box-sizing: border-box;
  //   transition: all .2s;
  // }

  // .menu-trigger {
  //   width: 100%;
  //   height: 20px;
  //   position: relative;
  // }

  // .menu-trigger span {
  //   background-color: #fff;
  //   width: 100%;
  //   height: 2px;
  //   position: absolute;
  //   left: 0;
  // }

  // .menu-trigger span:nth-of-type(1) {
  //   top: 0;
  //   transform: translateY(0) rotate(0);
  // }

  // .menu-trigger span:nth-of-type(2) {
  //   top: 9px;
  // }

  // .menu-trigger span:nth-of-type(3) {
  //   bottom: 0;
  //   transform: translateY(0) rotate(0);
  // }

  // /* Animation*/
  // .menu-trigger.active span:nth-of-type(1) {
  //   top: 50%;
  //   transform: rotate(45deg);
  // }

  // .menu-trigger.active span:nth-of-type(2) {
  //   transform: scaleX(0);
  // }

  // .menu-trigger.active span:nth-of-type(3) {
  //   top: 50%;
  //   transform: rotate(-45deg);
  // }

  svg {
    width: 46.32px;
    height: 40.62px;
  }

}

.btn {
  transition: opacity .2s;

  &:hover {
    opacity: .8;
  }
}

.el_btn {
  background: #fff;
  display: block;
  position: relative;
  padding: 20px 0;
  color: g.$green;
  text-align: center;
  transition: opacity .2s;
  font-weight: 600;

  .icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    margin: auto;

    svg {
      fill: g.$green;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &:hover {
    opacity: .8;
  }
}

.el_btn_or {
  background: g.$orange;
  display: block;
  position: relative;
  padding: 20px 0;
  border: 1px solid #fff;
  color: #fff;
  text-align: center;
  transition: opacity .2s;
  font-weight: 600;

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    margin: auto;

    svg {
      fill: #fff;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &:hover {
    opacity: .8;
  }
}

.el_btn_gl {
  background: g.$gold;
  display: block;
  position: relative;
  padding: 20px 0;
  border: 1px solid #fff;
  color: #fff;
  text-align: center;
  transition: opacity .2s;
  font-weight: 600;

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    margin: auto;

    svg {
      fill: #fff;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &:hover {
    opacity: .8;
  }
}

.el_btn_gr {
  background: g.$green ;
  display: block;
  position: relative;
  padding: 20px 0;
  border: 1px solid #fff;
  color: #fff;
  text-align: center;
  transition: opacity .2s;
  font-weight: 600;

  .icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    margin: auto;

    svg {
      fill: #fff;
    }
  }

  &:hover {
    opacity: .8;
  }
}

.el_btn_bl {
  background: g.$blue ;
  display: block;
  position: relative;
  padding: 20px 0;
  border: 1px solid #fff;
  color: #fff;
  text-align: center;
  transition: opacity .2s;
  font-weight: 600;

  .icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    margin: auto;

    svg {
      fill: #fff;
    }
  }

  &:hover {
    opacity: .8;
  }
}

.el_btn_transparent {
  background: transparent;
  display: block;
  position: relative;
  padding: 20px 0;
  border: 1px solid g.$blue;
  color: g.$blue;
  text-align: center;
  transition: opacity .2s;

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    margin: auto;

    svg {
      fill: g.$blue;
    }
  }

  &:hover {
    opacity: .8;
  }
}

.el_telBtn {
  display: block;
  padding: 15px 0;
  border: 1px solid #fff;
  color: #fff;
  font-size: 2rem;
  letter-spacing: 1px;
  text-align: center;
}

.el_btnText {
  padding-bottom: 5px;
  border-bottom: 1px solid g.$gold;
  color: g.$gold;
  transition: opacity .2s;

  @include g.mq-down(lg) {
    width: 100%;
    display: block;
    position: relative;
    padding: 15px 0;
    border: 1px solid g.$gold;
    text-align: center;
  }

  &:hover {
    opacity: .8;
  }

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;

    @include g.mq-down(lg) {
      position: absolute;
      top: 0;
      right: 10px;
      bottom: 0;
      margin: auto;
    }

    svg {
      fill: g.$gold;
    }
  }
}

.el_btnText_wh {
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 1);
  color: #fff;
  letter-spacing: .5px;
  transition: opacity .2s;

  @include g.mq-down(lg) {
    display: flex !important;
    justify-content: space-between;
  }

  &:hover {
    opacity: .8;
  }

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;

    @include g.mq-down(lg) {}

    svg {
      fill: #fff;
    }
  }
}

.el_btnText_red {
  display: block;
  padding-bottom: 5px;
  border-bottom: 1px solid g.$red;
  color: g.$red;
  transition: opacity .2s;

  &:hover {
    opacity: .8;
  }

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    position: relative;
    top: -2px;
    margin-left: 10px;
    vertical-align: middle;

    svg {
      fill: g.$red;
    }
  }
}

.el_btnText_or {
  display: block;
  padding-bottom: 5px;
  border-bottom: 1px solid g.$orange;
  color: g.$orange;
  transition: opacity .2s;

  &:hover {
    opacity: .8;
  }

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    position: relative;
    top: -2px;
    margin-left: 10px;
    vertical-align: middle;

    svg {
      fill: g.$orange;
    }
  }
}

.el_btnText_br {
  padding-bottom: 5px;
  border-bottom: 1px solid g.$gold;
  color: g.$gold;
  letter-spacing: .5px;

  @include g.mq-down(lg) {
    display: flex;
    justify-content: space-between;
  }

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    position: relative;
    top: -1px;
    margin-left: 10px;
    vertical-align: middle;

    fill: g.$gold;
  }

  &:hover {
    opacity: .8;
  }
}

.el_btnText_gl {
  padding-bottom: 5px;
  border-bottom: 1px solid g.$gold;
  color: g.$gold;
  letter-spacing: .5px;

  @include g.mq-down(lg) {
    display: flex;
    justify-content: space-between;
  }

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    position: relative;
    top: -1px;
    margin-left: 10px;
    vertical-align: middle;

    fill: g.$gold;
  }

  &:hover {
    opacity: .8;
  }
}

.el_btnText_bl {
  padding-bottom: 5px;
  border-bottom: 1px solid g.$blue;
  color: g.$blue;
  letter-spacing: .5px;

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    position: relative;
    top: -1px;
    margin-left: 10px;
    vertical-align: middle;

    svg {
      width: 100%;
      height: 100%;
      fill: g.$blue;
    }

  }

  &.--ver2 {
    @include g.mq-down(lg) {
      display: flex;
      justify-content: space-between;
      border: 1px solid g.$blue;
      width: 100%;
      padding: 15px 0;
      text-align: center;
      position: relative;
    }

    .icon {
      @include g.mq-down(lg) {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }
    }
  }

  &:hover {
    opacity: .8;
  }
}

.el_btnText_gr {
  padding-bottom: 5px;
  border-bottom: 1px solid g.$green;
  color: g.$green;
  letter-spacing: .5px;

  .icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    position: relative;
    top: -1px;
    margin-left: 10px;
    vertical-align: middle;

    svg {
      width: 100%;
      height: 100%;
      fill: g.$green;
    }

  }

  &.--ver2 {
    @include g.mq-down(lg) {
      display: flex;
      justify-content: space-between;
      border: 1px solid g.$green;
      width: 100%;
      padding: 15px 0;
      text-align: center;
      position: relative;
    }

    .icon {
      @include g.mq-down(lg) {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }
    }
  }

  &:hover {
    opacity: .8;
  }
}

.scrolldown2 {
  /*描画位置※位置は適宜調整してください*/
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: -4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #eee;
    animation:
      circlemove 1.6s ease-in-out infinite,
      cirlemovehide 1.6s ease-out infinite;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 2px;
    height: 75px;
    background: #eee;
  }
}




/*下からの距離が変化して丸の全体が上から下に動く*/
@keyframes circlemove {
  0% {
    bottom: 75px;
  }

  100% {
    bottom: -5px;
  }
}

/*上から下にかけて丸が透過→不透明→透過する*/
@keyframes cirlemovehide {
  0% {
    opacity: 0
  }

  50% {
    opacity: 1;
  }

  80% {
    opacity: 0.9;
  }

  100% {
    opacity: 0;
  }
}

.card-list-4col {
  display: flex;
  justify-content: space-between;
  gap: 3.64%;

  @include g.mq-down(lg) {
    display: block;
  }

  .card {
    width: 25%;
    max-width: 246px;

    @include g.mq-down(lg) {
      width: 100%;
      max-width: none;
      margin-bottom: 40px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.card-list-2col {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5.47%;

  @include g.mq-down(lg) {
    display: block;
  }

  .card {
    width: 47.26%;

    @include g.mq-down(lg) {
      width: 100%;
    }
  }
}

.card-list-3col {
  display: flex;
  flex-wrap: wrap;
  gap: 50px 4.15%;

  @include g.mq-down(lg) {
    display: block;
  }

  .card {
    width: 30.27%;

    @include g.mq-down(lg) {
      width: 100%;
    }
  }
}
