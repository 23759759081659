@use "../global" as g;

.p-facility {
  .mvSec {
    margin-bottom: 100px;

    @include g.mq-down(lg) {
      margin-bottom: 60px;
    }
  }

  .outlineSec {
    margin-bottom: 104px;

    @include g.mq-down(lg) {
      margin-bottom: 60px;
    }

    .inner {
      width: calc(100% - 60px);
      margin: 0 auto;

      .catch {
        font-size: 2.4rem;
        letter-spacing: 4px;
        line-height: 1.8;
        text-align: center;
        margin-bottom: 15px;
        color: g.$blue;

        @include g.mq-down(lg) {
          font-size: 1.9rem;
          text-align: left;
        }
      }

      .read {
        text-align: center;
        line-height: 2;

        @include g.mq-down(lg) {
          text-align: left;
        }
      }
    }
  }

  .roomSec {
    padding: 0 0 100px;

    @include g.mq-down(lg) {
      padding: 0 0 60px;
    }


    .subroom {
      width: calc(100% - 40px);
      max-width: 1206px;
      margin: 0 auto;

      .card {
        @include g.mq-down(lg) {
          margin-bottom: 40px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        figure {
          width: 100%;
          height: 27.818vw;
          max-height: 380px;
          margin-bottom: 20px;
          @include g.imgFit();

          @include g.mq-down(lg) {
            width: 100%;
            height: g.spvw(335);
            margin-bottom: 20px;
          }
        }

        .caption {
          .midashi {
            color: g.$blue;
            margin-bottom: 10px;

            @include g.mq-down(lg) {
              display: block;
              margin-bottom: 10px;
            }

            .en {
              font-size: 2.4rem;
              letter-spacing: 2px;
              margin-right: 20px;

              @include g.mq-down(lg) {
                font-size: 2rem
              }
            }

            .ja {
              @include g.mq-down(lg) {
                font-size: 1.3rem
              }
            }
          }

          .read {
            line-height: 2;
          }
        }
      }
    }
  }

  .otherroomSec {
    padding: 110px 0 100px;
    background: g.$yellow-thin;

    @include g.mq-down(lg) {
      padding: 60px 0 80px;
    }

    .inner {
      width: calc(100% - 60px);
      max-width: 1206px;
      margin: 0 auto;

      .card {
        @include g.mq-down(lg) {
          margin-bottom: 40px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        figure {
          width: 100%;
          height: 16.471vw;
          max-height: 225px;
          @include g.imgFit();
          margin-bottom: 20px;

          @include g.mq-down(lg) {
            height: 56vw;
          }
        }

        .caption {
          @include g.mq-down(lg) {
            padding: 0 20px;
          }

          .midashi {
            color: g.$blue;
            margin-bottom: 10px;

            .en {
              font-size: 2rem;
              letter-spacing: 2px;
            }

            .ja {
              @include g.mq-down(lg) {
                font-size: 1.3rem;
              }
            }
          }

          .read {
            line-height: 2;
          }

          .btn {
            display: inline-block;
            margin-top: 20px;
          }
        }
      }
    }
  }

  .gallerySec {
    padding: 80px 0 40px;

    .slides {
      .slick-dots {
        text-align: center;
      }
    }

    .more {
      text-align: center;
      margin-top: 50px;

      @include g.mq-down(lg) {
        width: calc(100% - 30px);
        margin: 50px auto 0;
      }
    }
  }

}
