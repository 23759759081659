@use "../global" as g;

.p-photo {
  margin-bottom: 120px;

  .mvSec {
    width: 100%;

    figure {
      width: 100%;
      height: 660px;
      @include g.imgFit();

      @include g.mq-down(lg) {
        height: 100vw;
      }
    }
  }

  .outlineSec {
    margin-top: -60px;
    position: relative;
    padding-bottom: 120px;

    @include g.mq-down(lg) {
      margin-top: 0;
      padding: 80px 0;

    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(50% - 198px);
      height: calc(100% - 273px);
      background-image: url(../images/photo/bg_outline-01.png);
      background-repeat: no-repeat;
      background-position: top left;
      background-size: cover;
      z-index: -1;
      opacity: 0.6;

      @include g.mq-down(lg) {
        width: 100%;
        height: g.spvw(1014);
        opacity: 1;
      }
    }

    .box1 {
      width: calc(100% - 80px);
      max-width: 1080px;
      margin: 0 auto 130px;
      display: flex;
      align-items: flex-end;

      @include g.mq-down(lg) {
        display: block;
        margin-bottom: 80px;
      }

      .photo {
        width: g.pcminvw(525);
        height: 690px;

        @include g.mq-down(lg) {
          width: 100%;
          height: g.spvw(387)
        }

        figure {
          width: 100%;
          height: 100%;
          @include g.imgFit();
        }
      }

      .text {
        padding: 60px 0;
        margin-left: -25px;

        @include g.mq-down(lg) {
          width: 100%;
          margin-top: -15px;
          margin-left: 0;
          padding: 0;
        }

        .midashi {
          width: calc(100% - 60px);
          margin-bottom: 60px;

          @include g.mq-down(lg) {
            width: calc(100% + 40px);
            margin-left: -20px;
            margin-bottom: 30px;
          }
        }

        .catch {
          padding-left: g.pcminvw(158);
          font-size: 2.2rem;
          line-height: 1.8;
          letter-spacing: 4px;
          margin-bottom: 35px;

          @include g.mq-down(lg) {
            font-size: 1.8rem;
            padding-left: 0;
            margin-bottom: 25px;
          }
        }

        .read {
          padding-left: g.pcminvw(158);
          letter-spacing: 2px;
          line-height: 2;

          @include g.mq-down(lg) {
            padding-left: 0;
          }
        }
      }
    }

    .box2 {
      width: calc(100% - 80px);
      max-width: 1080px;
      margin: 0 auto 100px;
      text-align: center;

      @include g.mq-down(lg) {
        text-align: left;
        margin-bottom: 60px;
      }

      .catch {
        color: g.$blue;
        font-size: 2.2rem;
        letter-spacing: 5px;
        margin-bottom: 35px;

        @include g.mq-down(lg) {
          font-size: 1.8rem;
          line-height: 2;
          margin-bottom: 20px;
          text-align: center;
        }
      }

      .read {
        line-height: 2;
      }
    }

    .box3 {
      .slides {
        .slide {
          margin: 0 7.5px;

          @include g.mq-down(lg) {
            margin: 0 10px;
          }

          figure {
            width: 725px;
            height: 483px;

            @include g.mq-down(lg) {
              width: calc(100vw - 80px);
              height: 78.667vw;
            }

            @include g.imgFit();
          }

        }
      }

      .more {
        margin-top: 30px;
        text-align: center;
      }
    }
  }

  .locationSec {
    background: g.$yellow-thin;
    padding: 110px 0 120px;
    position: relative;

    @include g.mq-down(lg) {
      padding: 60px 0 100px;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: calc(50% - 219px);
      height: 867px;
      background-image: url(../images/photo/bg_location-01.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @include g.mq-down(lg) {
        width: g.spvw(249);
        height: g.spvw(870);
      }
    }

    .midashi {
      padding-left: calc(50vw - 1080px / 2);
      color: g.$blue;
      margin-bottom: 80px;

      @include g.mq-down(lg) {
        padding-left: 20px;
        margin-bottom: 50px;
      }

      .en {
        font-size: 2.4rem;
        letter-spacing: 4px;
        position: relative;

        @include g.mq-down(lg) {
          font-size: 1.8rem;
          letter-spacing: 2px;
        }

        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background: g.$blue;
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -0.5px;
          z-index: 0;

          @include g.mq-down(lg) {
            left: auto;
            right: 0;
            width: g.spvw(93);
          }
        }

        span {
          position: relative;
          z-index: 1;
          background: g.$yellow-thin;
          padding-right: 30px;

          @include g.mq-down(lg) {
            background: transparent;
          }
        }
      }

      .ja {
        font-size: 2rem;
        letter-spacing: 4px;

        @include g.mq-down(lg) {
          font-size: 1.4rem;
          letter-spacing: 2px;
        }
      }
    }

    .box1 {
      margin-bottom: 80px;

      @include g.mq-down(lg) {
        margin-bottom: 60px;
      }

      .card {
        display: flex;
        align-items: center;
        padding-right: calc(50vw - 1300px / 2);

        @include g.mq-down(lg) {
          display: block;
          width: calc(100% - 40px);
          margin: 0 auto 60px;
          padding-left: 0;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &:nth-of-type(2n) {
          flex-direction: row-reverse;
          padding-right: 0;
          padding-left: calc(50vw - 1300px / 2);

          @include g.mq-down(lg) {
            padding: 0;
          }
        }

        .photo {
          width: g.pcvw(700);
          height: 440px;

          @include g.mq-down(lg) {
            width: 100%;
            height: g.spvw(335);
            margin-bottom: 30px;
          }

          figure {
            width: 100%;
            height: 100%;
            @include g.imgFit();

            img {
              object-position: center bottom;
            }
          }
        }

        .text {
          width: 633px;
          padding: 0 g.pcminvw(110);

          @include g.mq-down(lg) {
            width: 100%;
            padding: 0 20px;
          }

          .komidashi {
            color: g.$blue;
            margin-bottom: 30px;

            @include g.mq-down(lg) {
              margin-bottom: 20px;
            }

            .sub {
              font-weight: 700;

              @include g.mq-down(lg) {
                margin-bottom: 10px;
              }
            }

            .main {
              font-size: 2.4rem;
              letter-spacing: 2px;

              @include g.mq-down(lg) {
                font-size: 1.8rem;
              }
            }
          }

          .read {
            font-size: 1.3rem;
            line-height: 2;
          }

          .more {
            margin-top: 40px;

            @include g.mq-down(lg) {
              margin-top: 20px;
            }
          }
        }
      }
    }

    .box2 {
      width: calc(100% - 80px);
      max-width: 1080px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @include g.mq-down(lg) {
        display: block;
      }

      .card {
        width: percentage(500px / 1080px);
        margin-bottom: 50px;

        @include g.mq-down(lg) {
          width: 100%;
          margin-bottom: 60px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        figure {
          margin-bottom: 20px;
        }

        .text {
          .komidashi {
            color: g.$blue;
            margin-bottom: 10px;

            .sub {
              font-size: 1.3rem;
              font-weight: 700;

              @include g.mq-down(lg) {
                margin-bottom: 10px;
              }
            }

            .main {
              font-size: 2rem;
              letter-spacing: 2px;

              @include g.mq-down(lg) {
                font-size: 1.8rem;
              }
            }
          }

          .read {
            line-height: 2;
          }
        }
      }
    }
  }

  .attentionSec {
    padding: 100px 0 120px;

    @include g.mq-down(lg) {
      padding: 50px 0 110px;
    }

    .inner {
      width: calc(100% - 40px);
      max-width: 1000px;
      margin: 0 auto;

      .box {
        border: 1px solid g.$blue;
        padding: 40px;
        text-align: center;

        @include g.mq-down(lg) {
          padding: 40px 20px;
        }

        .midashi {
          font-size: 2rem;
          letter-spacing: 2px;
          color: g.$blue;
          margin-bottom: 20px;

          @include g.mq-down(lg) {
            font-size: 1.8rem;
            letter-spacing: 1px;
          }
        }

        .read {
          font-size: 1.3rem;
          letter-spacing: 1px;
          line-height: 2;

          @include g.mq-down(lg) {
            text-align: left;
          }
        }
      }
    }
  }

  .flowSec {
    background-color: g.$yellow-thin;
    background-image: url(../images/photo/bg_flow-01.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 90px;

    @include g.mq-down(lg) {
      padding-bottom: 20px;
    }

    .inner {
      width: calc(100% - 40px);
      max-width: 1000px;
      margin: 0 auto;

      .midashi {
        text-align: center;
        color: g.$blue;
        transform: translateY(-22px);

        @include g.mq-down(lg) {
          transform: translateY(-12px);
          margin-bottom: 20px;
        }

        .en {
          font-size: 3rem;

          @include g.mq-down(lg) {
            font-size: 1.8rem;
          }
        }
      }

      .intro {
        font-size: 2rem;
        letter-spacing: 2px;
        text-align: center;
        margin-bottom: 50px;

        @include g.mq-down(lg) {
          font-size: 1.8rem;
        }
      }

      .list {

        .item {
          display: flex;
          align-items: center;
          position: relative;
          z-index: 1;
          padding: 10px 0;

          @include g.mq-down(lg) {
            display: block;
            padding: 0;
          }

          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 400px + 55px + 44px;
            width: 1px;
            height: 100%;
            background: g.$blue;
            z-index: -1;

            @include g.mq-down(lg) {
              content: none;
            }
          }

          &:first-child {
            &::before {
              top: 50%;
              height: 50%;
            }
          }

          &:last-child {
            &::before {
              top: 0;
              height: 50%;
            }

            @include g.mq-down(lg) {
              .text {
                &::before {
                  height: 50%;
                }
              }
            }
          }

          .photo {
            width: 400px;
            margin-right: 55px;

            @include g.mq-down(lg) {
              width: 100%;
            }
          }

          .text {
            width: 545px;

            @include g.mq-down(lg) {
              width: 100%;
              padding: 40px 0 60px;
              position: relative;

              &::before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: calc(57px / 2);
                width: 1px;
                height: 100%;
                background: g.$blue;
                z-index: -1;
              }
            }

            dl {
              display: flex;
              justify-content: space-between;
              align-items: center;


              dt {
                .badge {
                  width: 90px;
                  height: 90px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-wrap: wrap;
                  border-radius: 50%;
                  border: 1px solid g.$blue;
                  background: #fff;
                  text-align: center;

                  @include g.mq-down(lg) {
                    width: 57px;
                    height: 57px;
                  }

                  p {
                    width: 100%;
                    color: g.$blue;

                    span {
                      display: block;

                      &:first-child {
                        font-size: 1.2rem;

                        @include g.mq-down(lg) {
                          font-size: 0.9rem;
                        }
                      }

                      &:last-child {
                        font-size: 2rem;

                        @include g.mq-down(lg) {
                          font-size: 1.4rem;
                        }
                      }
                    }
                  }
                }
              }

              dd {
                width: calc(100% - 145px);

                @include g.mq-down(lg) {
                  width: calc(100% - 77px);
                }

                .komidashi {
                  font-size: 2.2rem;
                  color: g.$blue;
                  letter-spacing: 4px;
                  margin-bottom: 10px;

                  @include g.mq-down(lg) {
                    font-size: 1.8rem;
                  }
                }

                .read {
                  line-height: 2;

                  @include g.mq-down(lg) {
                    font-size: 1.4rem;
                  }
                }
              }
            }
          }
        }
      }

    }
  }

  .faqSec {
    padding-bottom: 100px;

    @include g.mq-down(lg) {
      padding-bottom: 60px;
    }

    .inner {
      width: calc(100% - 40px);
      max-width: 1000px;
      margin: 0 auto;

      .midashi {
        text-align: center;
        color: g.$blue;
        margin-bottom: 60px;

        .en {
          font-size: 3rem;
          letter-spacing: 4px;
        }
      }

      dl {
        dt {
          @include g.mq-down(lg) {
            margin-left: -20px;
            width: calc(100% + 40px);
            padding-right: 20px;
          }
        }

        dd {
          @include g.mq-down(lg) {
            margin-left: -20px;
            padding-right: 0;
          }
        }
      }
    }
  }

}
