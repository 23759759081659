@use "../global" as g;

.p-story {

  main {
    margin-top: 0;
  }

  .mvSec {
    width: 100%;
    height: 656px;
    background-image: url(../images/story/img_mv-01_pc.jpg);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-bottom: 80px;

    @include g.mq-down(lg) {
      background-image: url(../images/story/img_mv-01_sp.jpg);
      height: g.spvw(455);
      margin-bottom: 60px;
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(#000000, 0.3);
      z-index: 0;
    }

    .inner {
      width: calc(100% - 40px);
      max-width: 1000px;
      height: 100%;
      margin: 0 auto;
      position: relative;
      z-index: 1;


      .midashi {
        width: 100%;
        color: #fff;
        position: absolute;
        bottom: 70px;

        @include g.mq-down(lg) {
          bottom: auto;
          top: 50%;
        }

        .sub {
          margin-bottom: 20px;
          letter-spacing: 2px;

          @include g.mq-down(lg) {
            font-size: 1.1rem;
            margin-bottom: 10px;
          }
        }

        .en {
          width: 540px;
          margin-bottom: 20px;

          @include g.mq-down(lg) {
            width: 295px;
            margin-bottom: 10px;
          }
        }

        .ja {
          font-size: 1.4rem;
          letter-spacing: 2px;

          @include g.mq-down(lg) {
            letter-spacing: 1px;
          }
        }
      }
    }
  }

  .story-sec {

    .inner {
      padding-left: calc(50vw - 1000px / 2);

      @include g.mq-down(lg) {
        padding-left: 0;
      }

      .intro {
        margin-bottom: 90px;

        @include g.mq-down(lg) {
          width: calc(100% - 80px);
          margin: 0 auto 80px;
        }

        .midashi {
          font-size: 2.2rem;
          margin-bottom: 15px;
          letter-spacing: 3px;

          @include g.mq-down(lg) {
            font-size: 1.6rem;
            margin-bottom: 20px;
          }
        }

        .read {
          line-height: 2;
          letter-spacing: 2px;

          @include g.mq-down(lg) {
            font-size: 1.3rem;
            letter-spacing: 1px;
          }
        }
      }

      .stories {
        padding-bottom: 160px;
        position: relative;

        @include g.mq-down(lg) {
          padding-bottom: 80px;
        }

        &::before {
          content: "";
          display: block;
          width: 1px;
          height: 100%;
          background: #fff;

          position: absolute;
          top: 0;
          left: g.pcminvw(120);
          z-index: 2;

          @include g.mq-down(lg) {
            left: 40px;
          }
        }

        &::after {
          content: "";
          display: block;
          width: 1px;
          height: 100%;
          background: #CCC5AD;

          position: absolute;
          top: 0;
          left: g.pcminvw(119);
          z-index: -1;

          @include g.mq-down(lg) {
            left: 39px;
          }
        }

        .still {
          position: relative;
          height: 601px;
          color: #fff;

          @include g.mq-down(lg) {
            height: auto;
          }

          .img {
            position: relative;
            width: 100%;
            height: 100%;
            @include g.imgFit();

            @include g.mq-down(lg) {
              height: 375px;
            }

            &::after {
              content: "";
              display: block;
              width: 100%;
              height: 100%;
              background-color: rgba(#000000, 0.3);
              position: absolute;
              top: 0;
              left: 0;
            }
          }

          .caption {
            position: absolute;
            top: 50%;
            left: g.pcminvw(103);
            color: #fff;

            @include g.mq-down(lg) {
              top: 115px;
              left: 20px;
            }

            .nakamidashi {
              font-size: 3rem;
              letter-spacing: 4px;
              margin-bottom: 10px;
            }

            .text {
              margin-left: g.pcminvw(50);

              @include g.mq-down(lg) {
                margin-left: 50px;

              }
            }
          }
        }

        .slider {
          padding-left: g.pcminvw(170);

          @include g.mq-down(lg) {
            padding-left: 70px;
          }

          .slides {
            .slide {
              margin-right: 10px;

              figure {
                width: 475px;

                @include g.mq-down(lg) {
                  width: g.spvw(263);
                }
              }
            }

            .slick-dots {
              text-align: left;
            }
          }

          .caption {
            padding-top: 20px;
          }

          .read {
            padding: 0 40px 0 0;
            margin-top: 0;
          }
        }

        .caption {
          .text {
            display: flex;

            @include g.mq-down(lg) {
              display: block;
            }

            dt {
              font-size: 2.8rem;
              letter-spacing: 2px;
              padding-right: 15px;

              @include g.mq-down(lg) {
                font-size: 2.5rem;
                padding-left: 0;
              }
            }

            dd {
              margin-top: 7px;
              letter-spacing: 2px;

              .komidashi {
                font-size: 2rem;
                margin-bottom: 15px;

                @include g.mq-down(lg) {
                  font-size: 1.8rem;
                  margin-bottom: 5px;
                }
              }
            }
          }
        }

        .read {
          font-size: 1.3rem;
          line-height: 2;

          @include g.mq-down(lg) {
            color: g.$text-color;
            width: 100%;
            padding: 0 40px 0 70px;
            margin: 30px auto 0;
          }
        }

        .story2 {
          margin-top: 50px;

          @include g.mq-down(lg) {
            margin-top: 60px;
          }
        }

        .story3 {
          margin-top: 100px;

          @include g.mq-down(lg) {
            margin-top: 60px;
          }
        }

        .story4 {
          margin-top: 8px;

          @include g.mq-down(lg) {
            margin-top: 60px;
          }
        }

        .story5 {
          margin-top: 90px;

          @include g.mq-down(lg) {
            margin-top: 60px;
          }
        }

        .story6 {
          margin-top: 100px;

          @include g.mq-down(lg) {
            margin-top: 60px;
          }
        }

        .story7 {
          margin-top: 65px;

          @include g.mq-down(lg) {
            margin-top: 60px;
          }
        }

        .story8 {
          margin-top: 100px;

          @include g.mq-down(lg) {
            margin-top: 60px;
          }
        }

        .story9 {
          margin-top: 100px;

          @include g.mq-down(lg) {
            margin-top: 60px;
          }
        }

        .story10 {
          margin-top: 100px;

          @include g.mq-down(lg) {
            margin-top: 60px;
          }
        }

        .story11 {
          margin-top: 30px;

          @include g.mq-down(lg) {
            margin-top: 60px;
          }
        }
      }
    }
  }

  .afterwedding-sec {
    background: g.$yellow-thin;
    padding-bottom: 100px;
    position: relative;

    @include g.mq-down(lg) {
      padding-top: 60px;
      padding-bottom: 70px;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: calc(50vw + 352px);
      width: calc(100% - (50vw + 352px));
      height: 1203px;
      background-image: url(../images/story/bg_afterwedding-01.png);
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: cover;
      mix-blend-mode: multiply;
      z-index: 0;

      @include g.mq-down(lg) {
        background-position: center;
        width: g.spvw(190);
        height: g.spvw(391);
        left: auto;
        right: 0;
        bottom: auto;
        top: calc(60px + 100vw + 200px);
      }
    }

    .inner {
      position: relative;
      z-index: 1;

      .intro {
        display: flex;
        padding-right: calc(50vw - 1200px / 2);
        margin-bottom: 80px;

        @include g.mq-down(lg) {
          display: block;
          margin-bottom: 60px;
        }

        .photo {
          width: g.pcvw(780);
          height: 530px;

          @include g.mq-down(lg) {
            width: 100%;
            height: 100vw;
          }

          figure {
            width: 100%;
            height: 100%;
            @include g.imgFit();
          }
        }

        .text {
          width: 503px;
          padding-left: g.pcminvw(50);

          @include g.mq-down(lg) {
            width: calc(100% - 40px);
            margin: 0 auto;
            padding-left: 0;
          }

          h2 {
            width: 451px;
            transform: translateY(-28px);
            margin-bottom: 10px;

            @include g.mq-down(lg) {
              width: 100%;
              max-width: 315px;
              transform: translateY(-20px);
            }
          }

          .catch {
            font-size: 2.4rem;
            letter-spacing: 2px;
            padding-left: g.pcminvw(73);
            margin-bottom: 30px;

            @include g.mq-down(lg) {
              font-size: 2rem;
              padding-left: 20px;
              margin-bottom: 20px;
            }
          }

          .read {
            line-height: 2;
            padding-left: g.pcminvw(73);
            margin-top: 15px;

          }
        }
      }

      .box {
        width: calc(100% - 80px);
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        @include g.mq-down(lg) {
          display: block;
          margin-top: 60px;
        }

        &:nth-last-of-type(1) {
          flex-direction: row;

          .text {
            padding-left: g.pcminvw(100);

            @include g.mq-down(lg) {
              padding-left: 0;
            }
          }
        }

        .photo {
          width: 50%;

          @include g.mq-down(lg) {
            width: 100%;
            height: g.spvw(295);
            margin-bottom: 30px;

            figure {
              width: 100%;
              height: 100%;
              @include g.imgFit();
            }
          }
        }

        .text {
          width: 50%;
          padding-left: g.pcminvw(60);

          @include g.mq-down(lg) {
            width: 100%;
            padding-left: 0;
          }

          .midashi {
            color: g.$blue;
            font-size: 1.3rem;
            margin-bottom: 10px;
          }

          .catch {
            font-size: 2.2rem;
            letter-spacing: 4px;
            color: g.$blue;
            margin-bottom: 10px;

            @include g.mq-down(lg) {
              font-size: 1.8rem;
              margin-bottom: 15px;
            }
          }

          .read {
            font-size: 1.3rem;

            @include g.mq-down(lg) {
              line-height: 2;
            }
          }

          .btn {
            display: inline-block;
            margin-top: 20px;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
