@use "../global" as g;

.p-spaceTemplate {
  .mvSec {
    figure {
      width: 100%;
      height: 660px;

      @include g.mq-down(lg) {
        width: 100%;
        height: g.spvw(375);
      }

      @include g.imgFit();
    }
  }

  .outlineSec {
    padding: 100px 0;

    @include g.mq-down(lg) {
      padding: 50px 0 80px;
    }

    .inner {
      .catch {
        font-size: 2rem;
        color: g.$blue;
        text-align: center;
        letter-spacing: 5px;
        margin-bottom: 40px;

        @include g.mq-down(lg) {
          font-size: 1.8rem;
          line-height: 1.8;
          margin-bottom: 30px;
        }
      }

      .read {
        text-align: center;
        line-height: 2;
      }
    }
  }

  .familiespartySec {
    padding-bottom: 130px;

    @include g.mq-down(lg) {
      padding-bottom: 100px;
    }

    .inner {
      .box {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        padding-left: calc(50vw - 1080px / 2);
        margin-bottom: 85px;

        @include g.mq-down(lg) {
          display: block;
        }

        &:nth-of-type(2n) {
          flex-direction: row;
          justify-content: flex-start;
          padding-left: calc(50vw - 1366px / 2);

          .text {
            padding-left: min(g.pcvw(127), 127px);
            padding-right: 0;

            @include g.mq-down(lg) {
              padding-left: 0;
            }
          }

          .photo {
            margin-left: 0;
            margin-right: 20px;

            .slides {
              .slick-dots {
                text-align: right;
              }
            }
          }
        }

        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }

        .text {
          width: min(g.pcvw(463), 463px);
          padding-right: min(g.pcvw(127), 127px);
          margin-top: 40px;

          @include g.mq-down(lg) {
            width: calc(100% - 80px);
            margin: 0 auto;
            padding: 0;
          }

          .midashi {
            color: g.$blue;
            margin-bottom: 30px;

            .en {
              letter-spacing: 2px;
              font-size: 1.8rem;
            }
          }

          .catch {
            letter-spacing: 4px;
            font-size: 2rem;
            line-height: 2;
            color: g.$blue;
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              font-size: 1.8rem;
              margin-bottom: 20px;
            }
          }

          .read {

            line-height: 2;
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              width: 100%;
            }
          }

          .btn {
            span {
              width: 100%;
            }
          }
        }

        .photo {
          width: min(g.pcvw(760), 760px);

          @include g.mq-down(lg) {
            width: calc(100% - 20px);
            margin-left: 20px;
            margin-bottom: 70px;
          }

          .slides {
            .slide {
              figure {
                @include g.mq-down(lg) {
                  width: 100%;
                  height: g.spvw(355);
                  margin-bottom: 0;
                  @include g.imgFit();
                }

              }
            }

            .slick-dots {
              text-align: left;
            }
          }
        }
      }
    }
  }
}
