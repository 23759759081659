@use "../global" as g;

header {
  background: transparent;
  //background: #fff;
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  &::after {
    @include g.mq-down(lg) {
      content: "";
      width: 100%;
      height: 75px;
      background: #fff;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transition: background-color .2s;
    }
  }

  @include g.mq-down(lg) {
    height: 75px;
  }

  .inner {
    position: relative;
    height: 100%;

    h1 {
      padding-top: 23px;
      padding-left: 20px;
      font-size: 1rem;

      @include g.mq-down(lg) {
        display: none;
      }
    }

    .logo {
      width: 136px;
      height: 51.48px;
      padding: 26px 0 0 36px;
      transition: opacity .2s;

      @include g.mq-down(lg) {
        width: 71.77px;
        height: 36.95px;
        position: static;
        padding: 24px 0 0 0;
        margin-left: 20px;

      }

      svg {
        width: 100%;
        height: 51.48px;

        @include g.mq-down(lg) {
          width: 71.77px;
          height: 36.95px;
        }

        ;
      }
    }

    .box {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 100px;

      @include g.mq-down(lg) {
        right: 75px;
      }

      .tel {
        @include g.mq-down(lg) {
          display: none;
        }

        p {
          color: g.$blue-bright;
          font-size: 1.4rem;
          letter-spacing: 1px;
          transition: color .2s;

          &.active {
            color: #fff !important;
          }
        }

        small {
          display: block;
          font-size: 1rem;
        }
      }

      .insta {
        width: 16px;
        height: 16px;
        margin-left: 30px;
        line-height: 1;

        @include g.mq-down(lg) {
          display: none;
        }

        svg {
          width: 18px;
          height: 18px;

          fill: g.$blue-bright;
          transition: fill .2s;

          &.active {
            fill: #fff !important;
          }

          @include g.mq-down(lg) {
            width: 22px;
            height: 22px;


          }
        }
      }

      .fairReserve {
        background: g.$green;
        width: 110px;
        height: 110px;
        margin-left: 30px;
        color: #fff;

        a {
          width: 100%;
          height: 100%;
          display: block;
          position: relative;

          p {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            svg {
              width: 26px;
              height: 26px;
              display: block;
              margin: 0 auto 10px;

              fill: #fff;
            }

            span {
              display: block;
              font-size: 1.2rem;
              font-weight: 700;
              line-height: 1.3;
              text-align: center;
            }
          }
        }
      }
    }

    .menu-btn {
      background: #fff;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;

      svg {
        &:nth-child(2) {
          display: none;
        }
      }

      &.active {
        svg {
          &:nth-child(1) {
            display: none;
          }

          &:nth-child(2) {
            display: block;
          }
        }
      }
    }

    .nav {

      background: #fff;
      width: 100%;
      max-width: 1100px;
      height: calc(100vh - 100px);
      visibility: hidden;
      position: absolute;
      top: 100px;
      right: 0;
      padding: 100px 140px 100px 102px;
      box-sizing: border-box;
      opacity: 0;
      overflow-x: hidden;
      overflow-y: scroll;

      @include g.mq-down(lg) {
        width: 100%;
        height: calc(100vh - 0px);
        max-width: none;
        top: 0px;
        padding: 75px 30px 60px 30px;
        box-sizing: border-box;
        z-index: -1;
      }

      .content-box {
        display: flex;
        justify-content: space-between;

        @include g.mq-down(lg) {
          display: block;
          margin-top: 30px;
        }

        .block {
          width: 28%;

          @include g.mq-down(lg) {
            width: 100%;
          }


          ul {
            @include g.mq-down(lg) {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;

              li {
                width: calc(50% - 10px);
              }
            }
          }

          svg {
            fill: g.$blue;
          }

          .inquiries {
            .komidashi {
              margin: 35px 0 25px;
              color: g.$green;
              font-size: 1.3rem;

              @include g.mq-down(lg) {
                margin: 50px 0 10px;
                text-align: center;
                font-size: 1.5rem;
              }
            }

            ul {
              @include g.mq-down(lg) {
                display: block;

                li {
                  width: 100%;
                }
              }
            }

            svg {
              fill: g.$green;
            }
          }
        }

        .sp-insta {
          display: none;

          @include g.mq-down(lg) {
            display: block;
            width: 27px;
            height: 27px;
            margin: 40px auto 0;

            svg {
              width: 100%;
              height: 100%;
              display: block;
              fill: g.$green;
            }
          }
        }
      }

      ul {
        li {
          a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 25px;
            padding-bottom: 10px;
            border-bottom: 1px solid #d0d0d0;
            font-size: 1.2rem;

            @include g.mq-down(lg) {
              height: 53px;
              justify-content: space-between;
              margin-bottom: 0;
              padding-bottom: 0;
            }
          }

          .ja {
            width: 214px;

            @include g.mq-down(lg) {
              width: calc(100% - 21px);
            }
          }

          svg {
            width: 21px;
            height: 20px;

            @include g.mq-down(lg) {
              position: static;
            }
          }

          &.gold {
            a {
              color: g.$gold;
            }

            svg {
              fill: g.$gold;
            }
          }
        }
      }
    }
  }

  .ResvBtn {
    background: g.$green;
    width: 100px;
    height: 100px;
    position: relative;
    margin-left: 30px;
    cursor: pointer;

    @include g.mq-down(lg) {
      width: 75px;
      height: 75px;
      margin-left: 20px;
    }

    &>div {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      figure {
        width: 28px;
        height: 28px;
        margin: 0 auto 8px;

        @include g.mq-down(lg) {
          width: 18px;
          height: 18px;
          margin-bottom: 6px;
        }
      }

      .word {
        color: #fff;
        font-size: 1.1rem;
        font-weight: 700;
        line-height: 1.6;
        letter-spacing: 0.5px;
        text-align: center;

        @include g.mq-down(lg) {
          font-size: 1.3rem;
          font-weight: 600;
        }

        span {
          display: inline-block;
          margin-bottom: 5px;
          font-size: 1.6rem;
        }
      }
    }
  }

  .ResvNav {
    background: g.$green;
    width: 480px;
    height: 100%;
    position: fixed;
    top: 0px;
    padding-top: 110px;
    z-index: -1;
    right: 0;
    color: #fff;
    opacity: 0;
    visibility: hidden;
    overflow-x: hidden;
    overflow-y: scroll;

    @include g.mq-down(lg) {
      width: 100%;
      height: 100%;
      padding-top: 80px;
      text-align: center;
      top: 0;
    }

    .midashi {
      margin: 40px 0 20px;
      font-size: 1.8rem;
      letter-spacing: 3px;
      text-align: center;

      @include g.mq-down(lg) {
        font-size: 1.7rem;
      }

      span {
        display: block;
        margin-top: 5px;
        font-size: 1.3rem;
      }
    }

    .block {
      margin-bottom: 30px;
      padding: 0 70px;


      &:nth-of-type(2) {
        &::after {
          content: "";
          display: block;
          margin-top: 15px;
          margin-bottom: -15px;
          border-bottom: 1px solid g.$grey-thin;
        }
      }

      @include g.mq-down(lg) {
        margin-bottom: 30px;
        padding: 0 30px;
      }
    }

    ul {
      li {
        margin-bottom: 10px;
        color: g.$green;

        small {
          display: block;
          margin-top: 10px;
          color: #fff;
          text-align: center;
        }
      }
    }

    .komidashi {
      margin-bottom: 5px;

      @include g.mq-down(lg) {
        margin-bottom: 10px;
        text-align: center;
        font-size: 1.3rem;
      }
    }

    .tel {
      display: block;
      margin-bottom: 5px;
      color: #fff;
      font-size: 1.6rem;
      letter-spacing: 1px;

      span {
        font-size: 2.1rem;
      }

      @include g.mq-down(lg) {
        font-weight: 500;
      }

      @include g.mq-up(lg) {
        pointer-events: none;
      }

      @include g.mq-down(lg) {
        margin-bottom: 10px;
        padding: 15px;
        text-align: center;
        font-size: 1.8rem;
        color: g.$green;
        background-color: #fff;
      }

      .openHour {
        @include g.mq-down(lg) {
          text-align: center
        }
      }
    }
  }

  &.is-mv {
    &::after {
      background: transparent;
    }

    .logo {
      figure {
        opacity: 0;
      }

      a {
        display: block;
        height: 51.48px;
        background-image: url(../images/common/logo_wh.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 136px 51.48px;

        @include g.mq-down(lg) {
          height: 39.64px;
          background-size: 71.77px 36.94px;
        }
      }
    }

    .box {
      .tel {
        p {
          color: #fff;
        }
      }

      .insta {
        svg {
          fill: #fff;
        }
      }
    }

    &.on {

      figure {
        opacity: 0 !important;
      }

    }

  }

  &.on {
    &::after {
      background: #fff !important;
    }

    .logo {
      figure {
        opacity: 1;
      }
    }
  }
}
