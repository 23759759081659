@use "../global" as g;

.p-blog-single {
  .archiveSec {
    margin-bottom: 170px;

    @include g.mq-down(lg) {
      margin-bottom: 80px;
    }

    .inner {
      @include g.mq-down(lg) {
        width: calc(100% - 40px);
        margin: 0 auto 40px;
      }

      .box {
        flex-direction: row;
      }

      .mainBody {
        width: 72%;

        @include g.mq-down(lg) {
          width: 100%;
        }

        .postTitle {
          font-size: 1.8rem;
          margin-bottom: 20px;
          padding-bottom: 15px;
          margin-top: -7px;
          border-bottom: 1px solid g.$grey-thin;
          color: g.$blue;

          @include g.mq-down(lg) {
            line-height: 1.8;
            font-size: 1.6rem;
            margin-top: 0;
            margin-bottom: 15px;
            padding-bottom: 10px;
          }
        }

        .dataBlock {
          display: flex;
          margin-bottom: 20px;

          @include g.mq-down(lg) {
            margin-bottom: 15px;
          }
        }

        .postCat {
          width: calc(100% - 7em);
          color: g.$blue;

          span {
            display: inline-block;
          }
        }

        .pubDate {
          letter-spacing: 1px;
          width: 7em;
          text-align: right;
          color: g.$blue;
        }

        .post {
          padding-bottom: 70px;
          margin-bottom: 30px;
          border-bottom: 1px solid g.$grey-thin;
          line-height: 2;

          @include g.mq-down(md) {
            margin-top: 20px;
          }
        }

        .singlePagenaviBox {
          position: relative;

          @include g.mq-down(lg) {
            margin-bottom: 60px;
          }

          .pagenavi {
            display: flex;
            justify-content: space-between;
            margin-bottom: 80px;

            @include g.mq-down(lg) {
              margin-bottom: 40px;
            }

            li {
              width: calc(50% - 0px);
              line-height: 1;
              margin-bottom: 0;
              font-size: 1.2rem;
              color: g.$blue;

              &.next {
                text-align: right;
                margin: 0;
              }

              &.prev {
                text-align: left;
                margin: 0;
              }
            }
          }

          .btn {
            margin: 0 auto;
            position: absolute;
            top: -5px;
            left: 50%;
            transform: translateX(-50%);

            .icon {
              margin-left: 0;
              margin-right: 10px;
              transform: rotate(-180deg);
            }

            @include g.mq-down(lg) {
              display: inline-block;
              position: static;
              transform: translate(0);

              .icon {
                position: relative;
                top: -2px;
                right: 0;
                margin-right: 10px;
              }
            }
          }
        }
      }

      aside {
        width: 200px;
        margin-top: 7px;

        @include g.mq-down(lg) {
          margin-top: 0;
          width: 100%;
        }
      }
    }
  }
}
