@use "../global" as g;

.p-clover {

  .mvSec {
    width: 100%;
    margin-bottom: 120px;

    @include g.mq-down(lg) {
      margin-bottom: 60px;
    }

    .catch {
      font-size: 2.4rem;
      text-align: center;
      letter-spacing: 4px;
      line-height: 2;
      color: g.$blue;
      margin-bottom: 40px;

      @include g.mq-down(lg) {
        font-size: 1.8rem;
        text-align: left;
        padding: 0 40px;
        letter-spacing: 3px;
        margin-bottom: 20px;
      }
    }

    .read {
      text-align: center;
      line-height: 2;
      margin-bottom: 100px;

      @include g.mq-down(lg) {
        text-align: left;
        padding: 0 40px;
        font-size: 1.4rem;
        margin-bottom: 60px;
      }
    }

    figure {
      width: 100%;
      height: 580px;
      @include g.imgFit();

      @include g.mq-down(lg) {
        height: 100vw;
      }
    }
  }

  .outlineSec {
    padding-bottom: 110px;

    @include g.mq-down(lg) {
      padding-bottom: 60px;
    }

    .inner {
      padding-left: calc(50vw - 1200px / 2);

      @include g.mq-down(lg) {
        padding-left: 0;
      }

      .box {
        display: flex;
        align-items: center;
        margin-bottom: 80px;

        @include g.mq-down(lg) {
          display: block;
          margin-bottom: 60px;
        }

        .photo {
          width: g.pcminvw(650);
          height: 450px;

          @include g.mq-down(lg) {
            width: calc(100% - 40px);
            height: g.spvw(206);
            margin: 0 auto;
          }

          figure {
            width: 100%;
            height: 100%;
            @include g.imgFit();
          }
        }

        .text {
          margin-left: -30px;

          @include g.mq-down(lg) {
            width: calc(100% - 40px);
            margin: 0 auto;
          }

          .midashi {
            width: 520px;
            margin-bottom: 40px;

            @include g.mq-down(lg) {
              width: 100%;
              margin-top: -15px;
              margin-bottom: 30px;
            }
          }

          .catch {
            padding-left: g.pcminvw(160);
            font-size: 2rem;
            line-height: 1.8;
            letter-spacing: 4px;
            margin-bottom: 20px;

            @include g.mq-down(lg) {
              padding: 0 20px;
              font-size: 1.8rem;
              letter-spacing: 2px;

            }
          }

          .read {
            padding-left: g.pcminvw(160);
            line-height: 2;

            @include g.mq-down(lg) {
              font-size: 1.4rem;
              padding: 0 20px;
            }
          }
        }
      }

      .slides {
        @include g.mq-down(lg) {
          padding-left: 40px;
        }

        .slide {
          margin-right: 8px;

          @include g.mq-down(lg) {
            margin-right: 20px;
          }

          figure {
            width: 600px;
            height: 380px;
            margin-bottom: 30px;
            @include g.imgFit();

            @include g.mq-down(lg) {
              width: g.spvw(295);
              height: g.spvw(240);
              margin-bottom: 20px;
            }

          }

          .caption {
            text-align: center;

            @include g.mq-down(lg) {
              text-align: left;
            }
          }
        }

        .slick-dots {
          text-align: left;
        }

        .slick-prev {
          left: -60px;
          top: calc(50% - 30px);

          @include g.mq-down(lg) {
            left: 10px;
          }
        }

        .slick-next {
          right: 60px;
          top: calc(50% - 30px);

          @include g.mq-down(lg) {
            right: 20px;
          }
        }
      }
    }
  }

  .planSec {
    background: g.$yellow-thin;
    padding: 90px 0 120px;

    @include g.mq-down(lg) {
      padding: 60px 0 60px;
    }

    .inner {
      width: calc(100% - 40px);
      max-width: 1200px;
      margin: 0 auto;

      .midashi {
        color: g.$blue;
        text-align: center;
        margin-bottom: 70px;

        @include g.mq-down(lg) {
          margin-bottom: 60px;
        }

        .en {
          font-size: 2.6rem;
          letter-spacing: 2px;
        }

        .ja {
          letter-spacing: 2px;
        }
      }

      .card {
        display: flex;
        align-items: center;
        background: #FFF;
        margin-bottom: 30px;

        @include g.mq-down(lg) {
          display: block;
        }

        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }

        .photo {
          width: g.pcminvw(637);
          height: 415px;

          @include g.mq-down(lg) {
            width: 100%;
            height: g.spvw(218);
          }

          figure {
            width: 100%;
            height: 100%;
            @include g.imgFit();
          }
        }

        .text {
          width: calc(100% - g.pcminvw(637));
          padding: 0 60px 0 80px;

          @include g.mq-down(lg) {
            width: 100%;
            padding: 20px;
          }

          .komidashi {
            position: relative;
            padding-bottom: 15px;
            margin-bottom: 10px;

            .en {
              font-size: 1.3rem;
              font-weight: 700;
              margin-bottom: 2px;
              color: g.$blue;
            }

            .ja {
              font-size: 2.2rem;
              letter-spacing: 2px;

              @include g.mq-down(lg) {
                font-size: 1.8rem;
                letter-spacing: 1px;
              }
            }

            &::after {
              content: "";
              display: block;
              position: absolute;
              bottom: 0px;
              left: -80 - 12px;
              width: calc(100% + 80px + 12px);
              height: 1px;
              background: g.$grey-thin;

              @include g.mq-down(lg) {
                width: 100%;
                left: 0;
              }

            }
          }

          .price {
            font-size: 3rem;
            color: g.$blue;
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;

            @include g.mq-down(lg) {
              font-size: 2.6rem;
              display: block;
            }

            p {
              @include g.mq-down(lg) {
                margin-bottom: 10px;

                &:nth-last-of-type(1) {
                  margin-bottom: 0;
                }
              }
            }

            .format {
              font-size: 1.3rem;
              line-height: 1.4;
              display: block;
            }

            .tax {
              font-size: 2rem;

              @include g.mq-down(lg) {
                font-size: 1.8rem;
              }
            }
          }

          .read {
            margin-bottom: 20px;
          }

          .detail {
            dl {
              display: flex;
              margin-bottom: 10px;

              @include g.mq-down(lg) {
                font-size: 1.2rem;
              }

              &:nth-last-of-type(1) {
                margin-bottom: 0;
              }

              dt {
                color: g.$blue;
                width: 98px;

                @include g.mq-down(lg) {
                  min-width: 70px;
                  width: auto;
                  margin-right: 0.5em;
                }
              }

              dd {
                &:nth-of-type(1) {
                  width: 45px;

                  @include g.mq-down(lg) {
                    width: auto;
                    margin-right: 0.5em;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .contactSec {
    padding: 100px 0 60px;

    @include g.mq-down(lg) {
      padding: 60px 0 120px;
    }

    .inner {
      width: calc(100% - 80px);
      max-width: 1000px;
      margin: 0 auto;

      .midashi {
        text-align: center;
        color: g.$blue;
        margin-bottom: 50px;

        .en {
          font-size: 2.4rem;
          letter-spacing: 2px;
        }
      }

      .box {
        display: flex;
        justify-content: center;

        @include g.mq-down(lg) {
          display: block;
        }

        .mail {
          padding-right: 70px;

          @include g.mq-down(lg) {
            padding-right: 0;
            margin-bottom: 20px;
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px solid g.$grey-thin;
          }

          .komidashi {
            font-size: 1.6rem;
            text-align: center;
            margin-bottom: 15px;
          }

          a {
            display: block;
            border: 1px solid g.$blue;
            padding: 25px 0;
            text-align: center;
            width: 350px;
            color: g.$blue;
            font-size: 1.6rem;
            transition: opacity .2s;

            @include g.mq-down(lg) {
              width: 100%;
              padding: 20px 0;
            }

            &:hover {
              opacity: 0.8;
            }
          }
        }

        .tel {
          padding-left: 108px;
          position: relative;

          @include g.mq-down(lg) {
            padding-left: 0;

          }

          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 1px;
            height: 100%;
            background: g.$grey-thin;

            @include g.mq-down(lg) {
              content: none;
            }
          }

          .komidashi {
            font-size: 1.6rem;
            text-align: center;
            margin-bottom: 10px;
          }

          a {
            display: block;
            color: g.$blue;
            font-size: 3.8rem;
            letter-spacing: 4px;
            display: flex;
            align-items: center;
            line-height: 1;
            margin-bottom: 10px;

            @include g.mq-down(lg) {
              font-size: 2.2rem;
              border: 1px solid g.$blue;
              padding: 20px 0;
              justify-content: center;
            }

            .icon {
              display: block;
              width: 32px;
              height: 32px;
              margin-right: 10px;

              @include g.mq-down(lg) {
                width: 18px;
                height: 18px;
              }
            }
          }

          .inquiry {
            text-align: center;
            font-size: 1.2rem;
            line-height: 1.8;
          }
        }
      }
    }
  }

}
