@use "../global" as g;

.p-access {
  .mvSec {
    figure {
      width: 100%;
      height: 660px;

      @include g.mq-down(lg) {
        width: 100%;
        height: g.spvw(375);
      }

      @include g.imgFit();
    }
  }

  .outlineSec {
    padding: 100px 0;

    @include g.mq-down(lg) {
      padding: 50px 0 80px;
    }

    .inner {
      .catch {
        font-size: 2rem;
        color: g.$blue;
        text-align: center;
        letter-spacing: 5px;
        margin-bottom: 40px;

        @include g.mq-down(lg) {
          font-size: 1.8rem;
          line-height: 1.8;
          margin-bottom: 30px;
        }
      }

      .read {
        text-align: center;
        line-height: 2;
      }
    }
  }

  .placeSec {
    background: g.$yellow-thin;
    padding: 35px 0;

    .inner {
      width: calc(100% - 40px);
      max-width: 1080px;
      margin: 0 auto;

      .box {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include g.mq-down(lg) {
          display: block;
        }

        .photo {
          width: percentage(550px / 1080px);

          @include g.mq-down(lg) {
            width: 100%;
            margin-bottom: 30px;
          }
        }

        .text {
          width: percentage(420px / 1080px);
          padding-right: percentage(60px / 1080px);

          @include g.mq-down(lg) {
            width: 100%;
            padding-right: 0;
          }

          .logo {
            border-bottom: 1px solid g.$blue;
            padding-bottom: 20px;
            margin-bottom: 30px;

            img {
              width: 100px;
            }
          }

          .data {
            dl {
              display: flex;
              margin-bottom: 25px;
              line-height: 2;

              @include g.mq-down(lg) {
                font-size: 1.3rem;
                margin-bottom: 15px;
              }

              &:nth-of-type(2) {
                dt {
                  line-height: 2.8;
                }

              }

              &:nth-last-of-type(1) {
                margin-bottom: 0;
              }

              dt {
                width: percentage(114px / 420px);
                color: g.$blue;

                @include g.mq-down(lg) {
                  width: 74px;
                }
              }

              dd {
                width: calc(100% - percentage(114px / 420px));
                letter-spacing: 0;

                @include g.mq-down(lg) {
                  width: calc(100% - 74px);
                }

                .tel {
                  color: g.$blue;
                  font-size: 1.8rem;
                  font-weight: 700;
                  letter-spacing: 1px;
                }
              }
            }
          }
        }
      }
    }
  }

  .accessSec {
    padding: 140px 0;

    @include g.mq-down(lg) {
      padding: 60px 0 80px;
    }

    .inner {
      width: calc(100% - 40px);
      max-width: 1000px;
      margin: 0 auto;

      .box {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include g.mq-down(lg) {
          display: block;
        }

        .midashi {
          display: flex;
          align-items: center;
          color: g.$blue;
          padding-bottom: 15px;
          margin-bottom: 30px;
          border-bottom: 1px solid g.$blue;
          font-size: 1.3rem;
          line-height: 1;
          font-weight: 700;

          @include g.mq-down(lg) {
            font-size: 1.4rem;
            padding-bottom: 10px;
            margin-bottom: 20px;
          }

          .icon {
            margin-right: 15px;
            display: flex;
            align-items: center;
            height: 28px;
          }
        }

        .catch {
          color: g.$blue;
          font-size: 1.4rem;
          letter-spacing: 2px;
          margin-bottom: 40px;

          @include g.mq-down(lg) {
            margin-bottom: 30px;
          }
        }

        .block {
          margin-bottom: 30px;

          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }

          .komidashi {
            font-weight: 700;
            font-size: 1.3rem;
            margin-bottom: 15px;

            @include g.mq-down(lg) {
              margin-bottom: 5px;
            }
          }

          .read {
            font-size: 1.3rem;
            line-height: 2;

            a {
              color: g.$blue;
              text-decoration: underline;

              &:hover {
                text-decoration: none;
              }
            }
          }
        }

        .car {
          width: 44%;
          margin-bottom: 40px;

          @include g.mq-down(lg) {
            width: 100%;
            margin-bottom: 30px;
          }

          .midashi {
            .icon {
              width: auto;



            }
          }
        }

        .train {
          width: 44%;
          margin-bottom: 40px;

          @include g.mq-down(lg) {
            width: 100%;
            margin-bottom: 30px;
          }

          .midashi {
            .icon {

              img {
                &:nth-child(1) {
                  margin-right: 5px;
                }
              }
            }
          }
        }

        .timetable {
          width: 100%;
          margin-bottom: 80px;

          @include g.mq-down(lg) {
            margin-bottom: 60px;
          }

          .note {
            font-size: 1.2rem;
            line-height: 1.8;
            text-align: center;

            @include g.mq-down(lg) {
              text-align: left;
            }
          }
        }

        .plane {
          width: 100%;
        }
      }

      .btn {
        width: 358px;
        margin: 60px auto 10px;
        font-size: 1.3rem;
        letter-spacing: 2px;

        @include g.mq-down(lg) {
          margin-top: 40px;
          width: 100%;
        }
      }
    }
  }

  .mapSec {
    margin-bottom: 80px;

    .map {
      width: 100%;
      height: 600px;
      margin-bottom: 30px;

      @include g.mq-down(lg) {
        height: g.spvw(375);
      }

      iframe {
        width: 100%;
        height: 100%;
      }
    }

    .more {
      text-align: center;
    }
  }
}
