@use "../global" as g;

.p-flow {
  .mvSec {
    margin-bottom: 90px;

    @include g.mq-down(lg) {
      margin-bottom: 60px;
    }

    .inner {
      figure {
        width: 100%;
        height: 660px;
        @include g.imgFit();

        @include g.mq-down(lg) {
          height: 100vw;
        }

        img {
          object-position: center top;
        }
      }
    }
  }

  .outlineSec {
    margin-bottom: 80px;

    @include g.mq-down(lg) {
      margin-bottom: 40px;
    }

    .inner {
      width: calc(100% - 60px);
      margin: 0 auto;

      .read {
        font-size: 1.8rem;
        line-height: 2;
        letter-spacing: 2px;
        text-align: center;
        color: g.$blue;

        @include g.mq-down(lg) {
          font-size: 1.4rem;
        }
      }
    }
  }

  .planningFlowSec {
    padding: 100px 0 0;

    @include g.mq-down(lg) {
      padding: 60px 0 0;
    }

    .inner {
      .midashi {
        margin-bottom: 60px;
        padding-left: calc(50vw - 550px);
        color: g.$blue;

        @include g.mq-down(lg) {
          padding: 0 30px;
        }

        .en {
          position: relative;
          margin-bottom: 5px;
          font-size: 3rem;
          letter-spacing: 2px;

          @include g.mq-down(lg) {
            font-size: 2rem;
          }

          span {
            background: #fff;
            display: inline-block;
            position: relative;
            z-index: 1;
            padding-right: 40px;

            @include g.mq-down(lg) {
              padding-right: 20px;
            }
          }

          &::before {
            content: "";
            background: g.$yellow-thin;
            width: 100%;
            height: 1px;
            display: block;
            position: absolute;
            top: 25px;
            left: 0;
            z-index: 0;

            @include g.mq-down(lg) {
              width: calc(100% + 30px);
              top: 15px;
            }
          }
        }
      }

      .contentBox {
        display: flex;
        align-items: center;
        margin-bottom: 60px;

        @include g.mq-down(lg) {
          display: block;
          margin-bottom: 50px;
        }

        &:nth-of-type(2n) {
          flex-direction: row-reverse;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .photo {
          height: 403px;

          @include g.mq-down(lg) {
            height: 53.333vw;
            margin-bottom: 20px;
          }

          figure {
            width: 100%;
            height: 100%;

            @include g.imgFit();
          }
        }

        .text {
          .komidashi {
            position: relative;
            margin-bottom: 20px;
            padding-bottom: 10px;
            font-size: 2rem;
            letter-spacing: 2px;

            @include g.mq-down(lg) {
              margin-bottom: 10px;
              padding-bottom: 5px;
            }

            &::after {
              content: "";
              background: g.$yellow-thin;
              width: 100%;
              height: 1px;
              display: block;
              position: absolute;
              bottom: 0;
            }

            .count {
              margin-right: 20px;
              color: g.$blue;
              font-size: 2.4rem;
            }
          }

          .read {
            line-height: 1.8;
          }
        }
      }

      .flow1 {
        padding-right: calc(50vw - 550px);
        padding-left: calc(50vw - 683px);

        @include g.mq-down(lg) {
          padding: 0 30px;
        }

        .photo {
          width: calc(100% - 493px);

          @include g.mq-down(lg) {
            width: 100%;
          }
        }

        .text {
          width: 493px;
          padding-left: 90px;
          box-sizing: border-box;

          @include g.mq-down(lg) {
            width: 100%;
            padding: 0;
          }

          .komidashi {
            &::after {
              width: calc(100% + 90px);
              left: -90px;

              @include g.mq-down(lg) {
                width: calc(100% + 30px);
                left: -30px;
              }
            }
          }
        }
      }

      .flow2 {
        padding-right: calc(50vw - 603px);
        padding-left: calc(50vw - 550px);

        @include g.mq-down(lg) {
          padding: 0 30px;
        }

        .photo {
          width: calc(100% - 513px);

          @include g.mq-down(lg) {
            width: 100%;
          }
        }

        .text {
          width: 513px;
          padding-right: 140px;
          box-sizing: border-box;

          @include g.mq-down(lg) {
            width: 100%;
            padding: 0;
          }

          .komidashi {
            &::after {
              width: calc(100% + 140px);
              left: 0;

              @include g.mq-down(lg) {
                width: calc(100% + 30px);
                left: -30px;
              }
            }
          }
        }
      }

      .flow3 {
        padding-right: calc(50vw - 550px);
        padding-left: calc(50vw - 603px);

        @include g.mq-down(lg) {
          padding: 0 30px;
        }

        .photo {
          width: calc(100% - 413px);

          @include g.mq-down(lg) {
            width: 100%;
          }
        }

        .text {
          width: 413px;
          padding-left: 113px;
          box-sizing: border-box;

          @include g.mq-down(lg) {
            width: 100%;
            padding-left: 0;
          }

          .komidashi {
            &::after {
              width: calc(100% + 113px);
              left: -113px;

              @include g.mq-down(lg) {
                width: calc(100% + 30px);
                left: -30px;
              }
            }
          }
        }
      }

      .flow4 {
        padding-right: calc(50vw - 683px);
        padding-left: calc(50vw - 550px);

        @include g.mq-down(lg) {
          padding: 0 30px;
        }

        .photo {
          width: calc(100% - 413px);

          @include g.mq-down(lg) {
            width: 100%;
          }
        }

        ;

        .text {
          width: 493px;
          padding-right: 63px;
          box-sizing: border-box;

          @include g.mq-down(lg) {
            width: 100%;
            padding-right: 0;
          }

          .komidashi {
            &::after {
              width: calc(100% + 63px);
              left: 0;

              @include g.mq-down(lg) {
                width: calc(100% + 30px);
                left: -30px;
              }
            }
          }
        }
      }

      .flow5 {
        padding-right: calc(50vw - 550px);
        padding-left: calc(50vw - 603px);

        @include g.mq-down(lg) {
          padding: 0 30px 60px 30px;
        }

        .photo {
          width: calc(100% - 513px);

          @include g.mq-down(lg) {
            width: 100%;
          }
        }

        .text {
          width: 513px;
          padding-left: 140px;
          box-sizing: border-box;

          @include g.mq-down(lg) {
            width: 100%;
            padding-left: 0;
          }

          .komidashi {
            &::after {
              width: calc(100% + 140px);
              left: -140px;

              @include g.mq-down(lg) {
                width: calc(100% + 30px);
                left: -30px;
              }
            }
          }
        }
      }
    }
  }

  .p-flow {
    &__schedule {
      padding: 60px 0 0;
      background: g.$yellow-thin;
      overflow: hidden;

      @include g.mq-down(lg) {
        padding-left: 0px;
      }

      &__head {
        margin-bottom: 70px;
        color: g.$blue;
        padding-left: calc(50vw - 550px);

        @include g.mq-down(lg) {
          padding-left: 30px;
        }

        &__en {
          position: relative;
          display: block;
          font-size: 2.8rem;
          letter-spacing: 4px;

          @include g.mq-down(lg) {
            font-size: 2rem;
            letter-spacing: 2px;

          }

          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 260px;
            bottom: 0;
            width: 100vw;
            height: 1px;
            margin: auto;
            background: g.$blue;

            @include g.mq-down(lg) {
              left: 160px;
            }
          }
        }

        &__ja {
          font-weight: 500;
          letter-spacing: 0.1em;

          @include g.mq-down(lg) {
            font-size: 13px;
          }
        }
      }

      &__step {
        position: relative;
        max-width: 1100px;
        margin: 0 auto;
        padding: 40px 35px 40px;
        background: #fff;
        text-align: center;
        margin-bottom: 95px;

        @include g.mq-down(lg) {
          width: calc(100% - 40px);
          padding: 30px 20px 30px;
          margin: 0 auto 70px;
        }

        &:nth-of-type(1),
        &:nth-of-type(2) {
          padding-bottom: 50px;

          @include g.mq-down(lg) {
            padding-bottom: 40px;
          }
        }

        &:nth-of-type(3) {
          padding-top: 40px;
          padding-bottom: 50px;
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          width: 1px;
          height: 95px;
          margin: auto;
          transform: translateY(100%);
          background: g.$grey-thin;

          @include g.mq-down(lg) {
            height: 70px;
          }
        }

        &::after {
          content: "";
          display: block;
          position: absolute;
          right: 0;
          bottom: -50px;
          left: 0;
          width: 0;
          height: 0;
          margin: auto;
          border-top: 10px solid g.$blue;
          border-left: 7.5px solid transparent;
          border-right: 7.5px solid transparent;

          @include g.mq-down(lg) {
            bottom: -40px;
          }
        }

        &__number {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
          transform: translateY(-55%);
          font-size: 2.5rem;
          letter-spacing: 2px;
          color: g.$blue;

          @include g.mq-down(lg) {
            font-size: 2.4rem;
          }

          span {
            padding-left: 0.2em;
            font-size: 4rem;
            letter-spacing: 2px;

            @include g.mq-down(lg) {
              font-size: 4rem;
            }
          }
        }

        &__head {
          font-size: 2.4rem;
          font-weight: 500;
          letter-spacing: 4px;

          @include g.mq-down(lg) {
            font-size: 1.8rem;
          }
        }

        &__txt {
          margin-top: 15px;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 0.08em;
          line-height: 1.8;

          @include g.mq-down(lg) {
            font-size: 13px;
            text-align: left;
          }
        }

        &__linkBox {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          @include g.mq-down(lg) {
            margin: 10px 0 0;
          }

          &__link {
            width: 242px;
            margin: 0 30px;

            @include g.mq-down(lg) {
              width: 100%;
              margin: 0;
            }

            a {
              width: 100%;
            }
          }
        }

        &__link {
          font-weight: 500;
          letter-spacing: 0.05em;
          text-align: center;
          margin-top: 27px;

          @include g.mq-down(lg) {
            margin-top: 20px;
          }

          a {
            width: 230px;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 3px 0 7px 0;
            margin: 0 auto;
            color: g.$blue;
            border-bottom: 1px solid currentColor;
            box-sizing: border-box;
            text-align: left;

            @include g.mq-down(lg) {
              width: 100%;
            }

          }

          &__arrow {
            position: absolute;
            top: 3px;
            right: 0;
            bottom: 0;
            width: 20px;
            height: 20px;
          }

          &.--green {
            a {
              color: g.$green;
            }
          }
        }

        &__list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: stretch;

          // margin-top: 10px;
          &__item {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            width: 30%;

            &:last-child {
              &::after {
                display: none;
              }
            }

            @include g.mq-down(lg) {
              width: 100%;

              &:nth-child(n+2) {
                margin-top: 50px;
              }
            }

            @include g.mq-up(lg) {
              &:nth-child(1) {
                width: 32%;
              }

              &:nth-child(2) {
                width: 27%;
              }
            }

            &::after {
              content: "";
              position: absolute;
              top: 0;
              right: -36px;
              bottom: 0;
              display: block;
              width: 11px;
              height: 28px;
              margin: auto;
              background: url(../images/common/step_arrow.svg) no-repeat center/100% auto;

              @include g.mq-down(lg) {
                top: auto;
                right: 0;
                bottom: -35px;
                left: 0;
                width: 8px;
                height: 21px;
                transform: rotate(90deg);
              }
            }

            &__circle {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 120px;
              height: 120px;
              border-radius: 50%;
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 0.1em;
              line-height: 1.85;
              text-align: center;
              color: g.$blue;

              @include g.mq-down(lg) {
                width: 102px;
                height: 102px;
                font-size: 13px;
              }

              &.--first {
                background-color: #F5F5F5;
              }

              &.--second {
                background-color: #F5F5F5;
              }

              &.--third {
                background-color: g.$blue;
                color: #fff;
              }
            }

            &__txt {
              width: calc(100% - 120px);
              padding-left: 20px;
              box-sizing: border-box;
              font-size: 13px;
              font-weight: 500;
              letter-spacing: 0.05em;
              line-height: 2.15;
              text-align: left;

              @include g.mq-down(lg) {
                width: calc(100% - 102px);
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  .step7Sec {
    margin-bottom: 140px;

    @include g.mq-down(lg) {
      margin-bottom: 80px;
    }

    .inner {
      .contentBox {
        display: flex;
        align-items: center;
        padding-right: calc(50vw - 550px);

        @include g.mq-down(lg) {
          display: block;
          padding-right: 0;
        }

        .photo {
          width: calc(100% - 469px);
          height: 455px;

          @include g.mq-down(lg) {
            width: 100%;
            height: 74.667vw;
            margin-bottom: 20px;
          }

          figure {
            width: 100%;
            height: 100%;

            @include g.imgFit();
          }
        }

        .text {
          width: 469px;
          padding-left: 140px;
          box-sizing: border-box;

          @include g.mq-down(lg) {
            width: calc(100% - 60px);
            margin: 0 auto;
            padding: 0;
            text-align: center;
          }

          .step {
            margin-bottom: 10px;
            color: g.$blue;
            font-size: 1.8rem;
            letter-spacing: 2px;

            @include g.mq-down(lg) {
              font-size: 2.4rem;
              list-style: 1;
              margin-bottom: 5px;
            }

            span {
              display: inline-block;
              padding-left: 10px;
              font-size: 3rem;

              @include g.mq-down(lg) {
                font-size: 4rem;
              }
            }
          }

          .subkomidashi {
            margin-bottom: 15px;
            color: g.$blue;
            font-size: 1.6rem;
            letter-spacing: 4px;

            @include g.mq-down(lg) {
              margin-bottom: 10px;
              font-size: 1.8rem;
              letter-spacing: 3px;
            }
          }

          .komidashi {
            margin-bottom: 40px;
            font-size: 3rem;
            letter-spacing: 2px;

            @include g.mq-down(lg) {
              font-size: 2rem;
              margin-bottom: 30px;
            }
          }

          .more {
            a {
              width: 100%;
              max-width: 210px;
              display: block;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 20px;

              @include g.mq-down(lg) {
                max-width: none;
              }

              &:last-child {
                margin-bottom: 0;
              }

              .icon {
                position: relative;
                top: 2px;
              }
            }
          }
        }
      }
    }
  }
}
