@use "../global" as g;

.othercontentsSec {
  padding: 100px 0;

  @include g.mq-down(lg) {
    padding: 60px 0;
  }

  .inner {
    padding-left: calc(50vw - 1080px / 2);

    @include g.mq-down(lg) {
      padding-left: 40px;
    }

    .midashi {
      color: g.$blue;
      margin-bottom: 40px;

      .en {
        font-size: 1.8rem;
        letter-spacing: 3px;
        position: relative;

        @include g.mq-down(lg) {
          letter-spacing: 2px;
        }

        &::after {
          content: "";
          display: block;
          width: calc(100% + 50vw - 550px);
          height: 1px;
          background: g.$blue;
          position: absolute;
          top: calc(50% - 0.5px);
          left: 0;
          z-index: -1;

          @include g.mq-down(lg) {
            width: calc(100% + 30px);
          }
        }

        span {
          display: inline-block;
          background: #fff;
          position: relative;
          padding-right: 40px;
          z-index: 1;

          @include g.mq-down(lg) {
            padding-right: 20px;
          }
        }
      }
    }

    .slides {

      .card {
        margin-right: 40px;
        margin-bottom: 20px;

        @include g.mq-down(lg) {
          margin-right: 20px;
          margin-bottom: 10px;
        }

        a {
          display: block;
        }

        figure {
          width: 246px;
          height: 246px;
          margin-bottom: 20px;
          @include g.imgFit();

          @include g.mq-down(lg) {
            width: g.spvw(295);
            height: g.spvw(220);
            max-height: none;
          }
        }

        .caption {
          color: g.$blue;

          .en {
            font-size: 1.4rem;
            letter-spacing: 2px;
            margin-bottom: 5px;
          }

          .ja {
            font-size: 1.3rem;
          }
        }
      }

      .slick-dots {
        text-align: left;
      }
    }
  }
}
