@use "../global" as g;

.form-sec {
  .formArea {

    @include g.mq-down(md) {
      padding-bottom: 20px;
      ;
    }

    .inner {
      position: relative;
      margin: 0 auto;
      max-width: 830px;
      width: calc(100% - 40px);

      .mailform {
        .row {
          display: flex;
          margin-bottom: 40px;

          @include g.mq-down(md) {
            margin-bottom: 20px;
          }

          dt {
            width: 90px;
            font-weight: 700;
            padding: 12px 0;
            color: g.$gold;

            @include g.mq-down(md) {
              width: 70px;
            }
          }

          dd {
            width: 100%;

            input[type=text],
            input[type=tel],
            input[type=email] {
              box-sizing: border-box;
              padding: 12px;
              font-size: 100%;
              width: 100%;
              background-color: #fff;
              border-width: 2px;
              border-color: g.$gold;
              border-radius: 10px;
              font-size: 1.5rem;
            }

            input[type=radio] {
              accent-color: g.$gold;
              width: 26px;
              height: 26px;
              margin-right: 3px;

              @include g.mq-down(md) {
                width: 22px;
                height: 22px;
              }
            }

            .zip-number {
              width: 20% !important;
            }

            .mwform-radio-field {
              display: inline-block;
              padding-top: 12px;
              margin-right: 20px;
              font-weight: 700;
              color: g.$gold;

              span {
                position: relative;
                top: 2px;

                @include g.mq-down(md) {
                  top: 0px;
                }
              }

              @include g.mq-down(md) {
                width: 100%;
              }
            }

            input.w-40 {
              width: 40%;

              @include g.mq-down(md) {
                width: 60%;
              }
            }

            input.w-30 {
              width: 30%;

              @include g.mq-down(md) {
                width: 40%;
              }
            }

            textarea {
              box-sizing: border-box;
              padding: 10px;
              width: 100%;
              background-color: #fff;
              border-width: 2px;
              border-color: g.$gold;
              border-radius: 10px;
              font-size: 1.6rem;
            }

            select {
              box-sizing: border-box;
              padding: 10px;
              width: 100%;
              background-color: #fff;
              border-width: 2px;
              border-color: g.$gold;
              font-size: 1.5rem;
            }

            .select-wrap {
              position: relative;
            }

            .select-wrap:before {
              z-index: 1;
              position: absolute;
              right: 15px;
              top: calc(50% - 5px);
              content: "";
              line-height: 43px;
              color: #7F878C;
              pointer-events: none;

              width: 8px;
              height: 8px;
              border-top: 2px solid g.$orange;
              border-right: 2px solid g.$orange;
              -webkit-transform: rotate(135deg);
              transform: rotate(135deg);
            }

            .checkbox {
              padding-top: 10px;
            }

            ul {
              padding: 10px;

              li {
                display: inline-block;
                margin-right: 10px;

                label {
                  display: flex;
                  align-items: center;

                  input[type=radio] {
                    accent-color: chocolate;
                    margin-right: 3px;
                  }
                }
              }
            }

            .error {
              margin-top: 10px;
            }
          }
        }
      }

      .privacy_box {
        box-sizing: border-box;
        padding: 20px;
        font-size: 100%;
        width: 100%;
        background-color: #fff;
        border-width: 2px;
        border-color: #E0BFAB;
        height: 200px;
        overflow: scroll;
        margin-top: 80px;

        .catch {
          font-size: 1.6rem;
          font-weight: 700;
          margin-bottom: 20px;
        }

        .read {
          font-weight: 500;
          line-height: 2;
          margin-bottom: 20px;
        }

        .or_ttl {
          color: #EF7D20;
        }

      }

      .privecy_check {
        margin: 50px auto;
        text-align: center;

        label {
          display: flex;
          align-items: center;
          justify-content: center;

          @include g.mq-down(md) {
            justify-content: flex-start;
            font-size: 1.5rem;
          }

          input[type=checkbox] {
            accent-color: chocolate;
            margin-right: 15px;
          }
        }
      }
    }
  }


  .btnArea {
    padding: 0;


  }

  .cancel {
    display: block;
    margin: 20px auto 0;
    font-weight: 500;

  }

  //チェックボックス
  input[type=checkbox] {
    display: none;
  }

  .mwform-checkbox-field-text {
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    padding: 5px 30px;
    position: relative;
    width: auto;
  }

  .mwform-checkbox-field-text::before {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    content: '';
    display: block;
    height: 16px;
    left: 5px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    width: 16px;
  }

  .mwform-checkbox-field-text::after {
    border-right: 2px solid g.$orange;
    border-bottom: 2px solid g.$orange;
    content: '';
    display: block;
    height: 20px;
    left: 12px;
    margin-top: -16px;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
    width: 9px;
  }

  input[type=checkbox]:checked+.mwform-checkbox-field-text::before {
    border-color: g.$orange
  }

  input[type=checkbox]:checked+.mwform-checkbox-field-text::after {
    opacity: 1;
  }

  // MW WP FORM 編集
  .mw_wp_form .horizontal-item+.horizontal-item {
    margin-left: 0;
  }

  //確認画面
  .mw_wp_form_confirm {
    .formArea {
      .inner {
        .mailform {
          border: 1px solid g.$gold;
          padding: 40px;
          background: #fff;

          @include g.mq-down(md) {
            padding: 20px;
          }

          .row {
            margin-bottom: 10px;
            padding-bottom: 10px;


            @include g.mq-down(md) {
              margin-bottom: 0;
              padding-bottom: 0px;

            }

            dd {
              padding-top: 12px;
              font-size: 1.8rem;
              color: g.$gold;

              @include g.mq-down(md) {
                font-size: 1.5rem;
              }

              .checkbox {
                padding-top: 0;
              }

              .note {
                display: none;
              }
            }
          }
        }
      }
    }

    .btnArea {
      margin-top: 50px;

      .inner {
        .reset_btn {
          display: none !important;
        }
      }
    }
  }
}
