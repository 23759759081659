@use "../global" as g;

@include g.mq-up(lg) {
  .basic-post {
    line-height: 2;
  }

  .basic-post img {
    width: inherit;
    max-width: 100%;
    height: auto;
  }

  .basic-post .alignnone {
    margin: 15px 0;
    display: block;
  }

  .basic-post .aligncenter {
    margin: 15px auto;
    display: block;
  }

  .basic-post img.alignleft {
    margin: 0px 20px 5px 0;
    display: inline;
    float: left;
  }

  .basic-post .alignright {
    margin: 0px 0 5px 20px;
    display: inline;
    float: right;
  }

  .basic-post ul li {
    list-style: disc;
    margin-left: 1.5em;
  }

  .basic-post ol li {
    list-style: decimal;
    margin-left: 1.5em;
  }

  .basic-post h1 {
    font-size: 24px;
    line-height: 1.13;
    letter-spacing: 1.9px;
    margin: 10px 0 46px;
    font-weight: 700;
  }

  .basic-post h2 {
    font-size: 20px;
    line-height: 1.13;
    letter-spacing: 1.9px;
    margin: 10px 0 46px;
    font-weight: 700;
  }

  .basic-post h3 {
    font-size: 18px;
    letter-spacing: 1.5px;
    line-height: 1.42;
    margin: 10px 0;
    font-weight: 700;
  }

  .basic-post h4 {
    font-size: 16px;
    letter-spacing: 1.5px;
    line-height: 1.42;
    margin: 10px 0;
    font-weight: 700;
  }

  .basic-post h5 {
    font-size: 13px;
    letter-spacing: 1.5px;
    line-height: 1.42;
    margin: 10px 0;
    font-weight: 700;
  }

  .basic-post h6 {
    font-size: 12px;
    letter-spacing: 1.5px;
    line-height: 1.42;
    margin: 10px 0;
    font-weight: 700;
  }

  .basic-post li {
    list-style: disc;
    margin-left: 1em;
    line-height: 2;
  }

  .basic-post a {
    text-decoration: underline;
    color: #736357;
  }

  .basic-post strong {
    font-weight: 700;
  }
}

@include g.mq-down(lg) {
  .basic-post h1 {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.6;
    letter-spacing: 0.3rem;
    margin: 10px 0 10px;
  }

  .basic-post h2 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.6;
    letter-spacing: 0.3rem;
    margin: 10px 0 10px;
  }

  .basic-post h3 {
    font-size: 1.8rem;
    letter-spacing: 0.25rem;
    font-weight: 700;
    line-height: 1.55;
    margin: 10px 0 10px;
  }

  .basic-post h4 {
    font-size: 1.6rem;
    letter-spacing: 0.25rem;
    font-weight: 700;
    line-height: 1.55;
    margin: 10px 0 10px;
  }

  .basic-post h5 {
    font-size: 1.4rem;
    letter-spacing: 0.25rem;
    font-weight: 700;
    line-height: 1.55;
    margin: 10px 0 10px;
  }

  .basic-post h6 {
    font-size: 1.3rem;
    letter-spacing: 0.25rem;
    font-weight: 700;
    line-height: 1.55;
    margin: 10px 0 10px;
  }

  .basic-post p {
    margin: 0 0 10px;
  }

  /*
	.basic-post h2,
	.basic-post h3,
	.basic-post p {
	    padding: 0px 3.3%;
	}
	*/
  .basic-post img {
    /*
	    margin: 0px 0 4vw -3.3%;
	    width: 106.6%;
	    */
    width: 100%;
    height: auto;
  }

  .basic-post .alignnone {
    margin: 15px 0;
  }

  .basic-post .aligncenter {
    margin: 15px 0 15px;
    display: block;
    width: 100%;
    max-width: none;
    margin-left: -20px;
    width: calc(100% + 40px);
  }

  .basic-post img.alignleft {
    width: 50%;
    margin: 0px 20px 5px 0;
    display: inline;
    float: left;
  }

  .basic-post .alignright {
    width: 50%;
    margin: 0px 0 5px 20px;
    display: inline;
    float: right;
  }

  .basic-post a {
    text-decoration: underline;
    color: #736357;
  }

  .basic-post strong {
    font-weight: 700;
  }
}
